<div
        class="container-fluid p-3 d-flex flex-column justify-content-between min-vh-100 overflow-auto"
>
    <div>
        <div class="small text-muted mb-3 border-bottom pb-2">
            <a class="text-muted" [routerLink]="['/']">Qwyk</a>
            &rsaquo;
            <a class="text-muted" [routerLink]="['/administration']"
            >{{ 'hub.administration.title' | translate }}</a
            ><!-- FIXME: link -->
            &rsaquo;
            <a class="text-muted font-weight-bold" [routerLink]="['./']"
            >{{ 'hub.administration.portal-users.title' | translate }}</a
            >
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <h3 class="font-weight-normal m-0">{{ 'hub.administration.portal-users.title' | translate }}</h3>
            <div>
                <div class="btn-group btn-group-toggle mr-1 d-none">
                    <label class="btn-outline-primary">
                        <input
                            type="checkbox"
                            [(ngModel)]="isTableView"
                        />
                        <fa-icon
                            [icon]="['far', !isTableView ? 'table' : 'list']"
                        ></fa-icon>
                    </label>
                </div>
                <button
                    *ngIf="(isMagayaNetwork$ | async) === false"
                    class="btn btn-outline-primary"
                    [routerLink]="['./new']"
                >
                    <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
                    {{ 'hub.administration.portal-users.create-portal-user' | translate }}
                </button>
            </div>
        </div>
        <div class="my-3">

            <qwyk-controlled-by-magaya-warning *ngIf="isMagayaNetwork$ | async"></qwyk-controlled-by-magaya-warning>

            <p-table
                *ngIf="isTableView"
                #table
                [resizableColumns]="true"
                [paginator]="true"
                [lazy]="true"
                [value]="customerUsers$ | async"
                [reorderableColumns]="true"
                [columns]="selectedColumns"
                (onLazyLoad)="loadCustomerUsersLazy($event)"
                [loading]="loading$ | async"
                [rows]="(pagination$ | async)?.per_page"
                [rowHover]="true"
                [totalRecords]="(pagination$ | async)?.total"
                currentPageReportTemplate="{{ 'common.showing' | translate }} {first} {{ 'common.to_pagination' | translate }} {last} {{ 'common.of' | translate }} {totalRecords} {{ 'hub.menu.side-menu.users' | translate | lowercase }}"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[25, 50, 100, 200]"
                [first]="
                    (pagination$ | async)?.per_page *
                    ((pagination$ | async)?.current_page - 1)
                "
                (onColReorder)="onTableColReorder($event)"
            >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 48px"></th>
                        <ng-container *ngFor="let column of columns">
                            <th
                                *ngIf="column.sort"
                                [pSortableColumn]="column.sort_key"
                                pReorderableColumn
                            >
                                {{ column.header }}
                                <p-sortIcon [field]="column.sort_key"></p-sortIcon>
                            </th>
                            <th
                                *ngIf="!column.sort"
                                pReorderableColumn
                            >
                                {{ column.header }}
                            </th>
                        </ng-container>
                        <th>{{ 'common.tags' | translate }}</th>
                        <th class="text-center" style="width: 165px"></th>
                    </tr>
                    <tr [formGroup]="searchForm">
                        <th></th>
                        <th
                            *ngFor="let column of columns"
                            class="font-weight-normal"
                        >
                            <input
                                *ngIf="column.filter && column.field === 'email'"
                                type="text"
                                formControlName="email"
                                class="form-control form-control-sm"
                                placeholder="{{ 'common.search-by' | translate : { value: 'common.email' | translate } }} "
                            />
                            <input
                                *ngIf="column.filter && column.field === 'name'"
                                type="text"
                                formControlName="name"
                                class="form-control form-control-sm"
                                placeholder="{{ 'common.search-by' | translate : { value: 'common.name' | translate } }}"
                            />
                            <ng-select
                                *ngIf=" column.filter && column.field === 'team_name'"
                                class="custom custom-sm"
                                bindValue="id"
                                bindLabel="name"
                                [items]="customers$ | async"
                                [loading]="customersLoading"
                                [typeahead]="customerInput$"
                                formControlName="team_id"
                                size="sm"
                                placeholder="{{ 'hub.administration.portal-users.type-to-search' | translate }}"
                                appendTo="body"
                            >
                            </ng-select>
                        </th>
                        <th class="font-weight-normal">
                            <ng-select
                                [items]="[
                                    {
                                        value: 'unverified',
                                        label: 'hub.home.email-verification-pending',
                                        icon: 'envelope',
                                        style: 'badge-outline-warning'
                                    },
                                    {
                                        value: 'unapproved',
                                        label: 'hub.administration.portal-users.approval-pending',
                                        icon: 'shield-check',
                                        style: 'badge-outline-danger'
                                    },
                                    {
                                        value: 'trashed',
                                        label: 'hub.administration.portal-users.deactivated-rejected',
                                        icon: 'ban',
                                        style: 'badge-danger'
                                    },
                                    {
                                        value: 'active',
                                        label: 'common.active',
                                        icon: 'check-circle',
                                        style: 'badge-outline-success'
                                    }
                                ]"
                                bindValue="value"
                                formControlName="tag"
                                class="custom custom-sm"
                                size="sm"
                                placeholder="{{ 'hub.administration.portal-users.select-a-tag' | translate }}"
                                appendTo="body"
                            >
                                <ng-template
                                    ng-option-tmp
                                    ng-label-tmp
                                    let-item="item"
                                >
                                    <span class="badge" [ngClass]="item.style">
                                        <fa-icon
                                                [icon]="['fas', item.icon]"
                                        ></fa-icon>
                                        {{ item.label | translate }}
                                    </span>
                                </ng-template>
                            </ng-select>
                        </th>

                        <th class="text-center">
                            <button
                                class="btn btn-primary btn-sm"
                                type="submit"
                                (click)="search()"
                                [disabled]="!searchForm.dirty"
                            >
                                {{ 'common.search' | translate }}
                            </button>
                            <button
                                class="btn btn-light btn-sm ml-1"
                                type="button"
                                (click)="resetSearch()"
                            >
                            {{ 'common.reset' | translate }}
                            </button>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-user let-columns="columns">
                    <tr>
                        <td>
                            <img
                                [src]="
                                    'https://www.gravatar.com/avatar/' +
                                    (user.email | md5) +
                                    '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' +
                                    (user.name | urlEncode) +
                                    '/64/097aff/ffffff/2//false/true/true'
                                "
                                alt="{{ 'common.avatar' | translate }}"
                                style="width: 28px; height: 28px"
                                class="rounded-circle border"
                            />
                        </td>
                        <td *ngFor="let column of columns">
                            <ng-container [ngSwitch]="column.field">
                                <ng-container *ngSwitchCase="'name'">
                                    <a *ngIf="!user.deactivated && ((isMagayaNetwork$ | async) === false || !user.approved)"
                                       [ngStyle]="{'no-access': user.deactivated || ((isMagayaNetwork$ | async) === false || !user.approved)}"
                                       [routerLink]="
                                            !user.deactivated && ((isMagayaNetwork$ | async) === false || !user.approved)
                                                ? ['./', user.id]
                                                : null
                                        "
                                    >
                                        {{ user.name }}
                                    </a>
                                    <span *ngIf="user.deactivated || ((isMagayaNetwork$ | async) && user.approved)">
                                        {{ user.name }}
                                    </span>
                                </ng-container>
                                <ng-container *ngSwitchCase="'team_name'">
                                    <a
                                        *ngIf="user.team_id"
                                        [routerLink]="[
                                            '/administration',
                                            'portal-teams',
                                            user.team_id
                                        ]"
                                        class="sneaky-link"
                                    >
                                        {{ user.team_name }}
                                    </a>
                                </ng-container>
                                <ng-container *ngSwitchCase="'created_at'">
                                    {{ user.created_at | date }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'activated'">
                                    <fa-icon
                                        [icon]="['fas', !user.deactivated ? 'check' : 'times']"
                                        [fixedWidth]="true"
                                        [ngClass]="!user.deactivated ? 'text-success' : 'text-danger'"
                                    ></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <ng-container *ngIf="!column.nested">
                                        {{ user[column.field] }}
                                    </ng-container>
                                    <ng-container *ngIf="column.nested">
                                        {{ user | traverse: column.nested }}
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <qwyk-customer-user-status-badge [user]="user"></qwyk-customer-user-status-badge>
                        </td>
                        <td>
                            <ng-container *ngIf="!user.approved && !user.deactivated">
                                <button
                                    [routerLink]="['./', user.id]"
                                    class="btn btn-sm btn-outline-success"
                                    ngbTooltip="{{ 'hub.administration.portal-users.approve-user' | translate }}"
                                >
                                    <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
                                </button>
                                <button
                                    [routerLink]="['./', user.id]"
                                    class="btn btn-sm btn-outline-danger ml-1"
                                    ngbTooltip="{{ 'hub.administration.portal-users.reject-user' | translate }}"
                                >
                                    <fa-icon [icon]="['far', 'times-circle']"></fa-icon>
                                </button>
                            </ng-container>
                            <button
                                *ngIf="user.approved && !user.deactivated"
                                class="btn btn-sm btn-outline-primary"
                                ngbTooltip="{{ 'hub.administration.portal-users.impersonate-user' | translate }}"
                                (click)="onImpersonateUser(user)"
                            >
                                <fa-icon [icon]="['far', 'user-ninja']"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="loadingbody" let-columns>
                    <tr>
                        <td
                            [attr.colspan]="3 + columns.length"
                            class="py-4 text-muted text-center"
                        >
                            {{ 'common.loading' | translate }}...
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td
                            [attr.colspan]="3 + columns.length"
                            class="py-4 text-muted text-center"
                        >
                            {{ 'common.nothing_found' | translate }}
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="footer" let-selectedCols>
                    <tr>
                        <td [attr.colspan]="3 + selectedColumns.length">
                            <div class="d-flex w-100 justify-content-between">
                                <p-multiSelect
                                    [options]="columns"
                                    [(ngModel)]="selectedColumns"
                                    appendTo="body"
                                    optionLabel="label"
                                    optionValue="value"
                                    [filter]="true"
                                    [showToggleAll]="true"
                                    [selectAll]="selectAll"
                                    (onSelectAllChange)="onSelectAllChange($event)"
                                    [style]="{ minWidth: '250px' }"
                                    placeholder="{{ 'common.choose-columns' | translate }}"
                                >
                                    <ng-template let-value pTemplate="selectedItems">
                                        <span *ngFor="let option of selectedColumns | slice:0:2; let isLast = last">
                                            {{ option.header }}<span *ngIf="!isLast">, </span>
                                        </span>
                                        <span class="ng-value" *ngIf="selectedColumns.length > 2">
                                            <span class="ng-value-label"> {{ 'common.and' | translate }} {{ selectedColumns.length - 2}} {{ 'common.more' | translate }}...</span>
                                        </span>
                                    </ng-template>
                                    <ng-template let-value pTemplate="item">
                                        {{ value.label }}
                                    </ng-template>
                                </p-multiSelect>

                                <button
                                    class="btn btn-primary btn-sm"
                                    (click)="table.exportCSV()"
                                >
                                    <fa-icon
                                        [icon]="['far', 'cloud-download']"
                                        class="mr-1"
                                    ></fa-icon>
                                    {{ 'common.download' | translate }}
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <ng-container *ngIf="!isTableView">
                <ul class="list-group list-group-flush">
                    <li
                        *ngFor="let user of customerUsers$ | async"
                        [routerLink]="[user.id]"
                        class="list-group-item list-group-item-action d-flex align-items-center"
                    >
                        <div class="mr-3">
                            <img
                                [src]="
                                    'https://www.gravatar.com/avatar/' +
                                    (user.email | md5) +
                                    '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' +
                                    (user.name | urlEncode) +
                                    '/64/097aff/ffffff/2//false/true/true'
                                "
                                alt="{{ 'common.avatar' | translate }}"
                                style="width: 36px; height: 36px"
                                class="rounded-circle border"
                            />
                        </div>
                        <div class="flex-grow-1">
                            <a
                                class="font-weight-bold"
                                [routerLink]="[user.id]"
                            >{{ user.name }}</a
                            >
                            <div>{{ user.email }}</div>
                        </div>
                    </li>
                </ul>
            </ng-container>
        </div>
    </div>
</div>
