import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';

import { environment } from '@qwyk/hub/environment';

import { AppModule } from './app/app.module';
import { versions } from './environments/versions';

Sentry.init({
    enabled: environment.sentry.enabled,
    dsn: environment.sentry.dsn,
    environment: environment.name,
    release: versions.version,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error'],
        }),
    ],
    tracePropagationTargets: environment.sentry.traceOrigins,
    tracesSampleRate: environment.sentry.tracesSampleRate,
    beforeSend: (event) => {
        if (
            typeof event?.message === 'string' &&
            event?.message.includes('[object Object]') &&
            event?.extra?.arguments &&
            Array.isArray(event.extra.arguments) &&
            event?.extra?.arguments.length > 0 &&
            event.extra.arguments[0]?.message
        ) {
            event.message = event.extra.arguments[0].message;
        }
        return { ...event };
    },
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err =>  console.error(err));
