<div class="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 p-0" [@fadeIn]>
    <div class="head mt-3 mb-5 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center flex-shrink-1">
            <button class="btn btn-sm btn-light mr-1" [routerLink]="['../']">
                <fa-icon [icon]="['far', 'arrow-alt-left']"></fa-icon>
            </button>
            <fa-icon [icon]="['fad', 'truck']" size="2x" class="text-primary mr-3" fixedWidth="true"></fa-icon>
            <div>
                <span
                    class="lead">{{ (loading$ | async) === false && (loadingError$ | async) === false ? form.get('id').value === null ? 'New' : 'Update' : null }}
                    {{ form.get('schedule_type').value | titlecase}}carriage</span>
            </div>
        </div>
    </div>

    <ng-container *ngIf="loading$ | async">
        <div class="text-muted text-center">
            <fa-icon [icon]="['far', 'spinner']" pulse="true" size="3x"></fa-icon>
        </div>
    </ng-container>

    <ng-container *ngIf="loadingError$ | async">
        <div class="text-center text-danger">
            <fa-icon [icon]="['far', 'exclamation-triangle']" size="2x" class="mb-2"></fa-icon>
            <p>There was an error, please reload the page, or contact support if the problem persists</p>
        </div>
    </ng-container>

    <form class="qw-form" [@fadeIn] [formGroup]="form" (submit)="submit()"
        *ngIf="(loading$ | async) === false && (loadingError$ | async) === false">
        <p class="lead">
            Routing
        </p>
        <div class="form-row mt-5">
            <div class="form-group col-auto text-center">
                <div>
                    <div class="d-inline-block text-light rounded-circle p-2 shadow-sm" style="width: 4em; height: 4em;"
                        [ngClass]="form.get('schedule_type')?.value === 'on' ? 'bg-primary' : 'bg-secondary'">
                        <fa-icon [icon]="['fad', form.get('schedule_type')?.value === 'on' ? 'ship' : 'industry-alt']"
                            style="font-size:2em;" [fixedWidth]="true">
                        </fa-icon>
                    </div>
                </div>
                <label>{{ form.get('schedule_type').value === 'pre' ? 'Place of receipt' : 'Port of discharge' }}</label>
                <div>
                    <p-autoComplete formControlName="origin" [forceSelection]="true"
                        [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)"
                        optionLabel="display_name" inputStyleClass="form-control" [required]="true"
                        (onSelect)="onLocationSelect($event.value, 'origin')" (onClear)="onLocationClear($event, 'origin')">
                        <ng-template let-value pTemplate="item">
                            <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight"></div>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="validation-error" *ngIf="form.get('origin').invalid && form.get('origin').touched">
                    Select a place from the list.
                </div>
                <div *ngIf="form.get('origin').value; let v;" class="text-muted mt-1">{{ v.locode }}</div>
            </div>
            <div class="form-group col text-center">
                <div>
                    <div class="d-inline-block text-dark rounded-circle" style="width: 4em; height: 4em; padding: 1em;">
                        <fa-icon [icon]="['fad', 'truck']" style="font-size:2em;" [fixedWidth]="true">
                        </fa-icon>
                    </div>
                </div>
                <label>Type</label>
                <select class="form-control" formControlName="schedule_type" style="margin: 0 auto">
                    <option [ngValue]="'pre'">Precarriage</option>
                    <option [ngValue]="'on'">Oncarriage</option>
                </select>
            </div>
            <div class="form-group col-auto text-center">
                <div>
                    <div class="d-inline-block text-light rounded-circle p-2 shadow-sm" style="width: 4em; height: 4em;"
                        [ngClass]="form.get('schedule_type').value === 'pre' ? 'bg-primary' : 'bg-secondary'">
                        <fa-icon [icon]="['fad', form.get('schedule_type').value === 'pre' ? 'ship' : 'industry-alt']"
                            style="font-size:2em;" [fixedWidth]="true">
                        </fa-icon>
                    </div>
                </div>
                <label>{{ form.get('schedule_type').value === 'pre' ? 'Port of loading' : 'Place of delivery' }}</label>
                <div>
                    <p-autoComplete formControlName="destination" [forceSelection]="true"
                        [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)"
                        optionLabel="display_name" inputStyleClass="form-control" [required]="true"
                        (onSelect)="onLocationSelect($event.value, 'destination')"
                        (onClear)="onLocationClear($event, 'destination')">
                        <ng-template let-value pTemplate="item">
                            <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight"></div>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="validation-error"
                    *ngIf="form.get('destination').invalid && form.get('destination').touched">
                    Select a place from the list.
                </div>
                <div *ngIf="form.get('destination').value; let v;" class="text-muted mt-1">{{ v.locode }}</div>

            </div>
        </div>
        <div class="form-group text-center">
            <!-- <label>Transport name</label> -->
            <select id="vessel_name" name="vessel_name" class="form-control" style="margin: 0 auto;"
                formControlName="vessel_name"
                [ngClass]="{'is-invalid': form.get('vessel_name').touched && form.get('vessel_name').invalid}">
                <option [value]="null" disabled selected>Select a transport name</option>
                <option [ngValue]="'Truck'">Truck</option>
                <option [ngValue]="'Rail'">Rail</option>
                <option [ngValue]="'Barge'">Barge</option>
                <option [ngValue]="'Feeder'">Feeder</option>
                <option [ngValue]="'Precarriage'">Precarriage</option>
                <option [ngValue]="'Oncarriage'">Oncarriage</option>
            </select>
            <div class="invalid-feedback">Select a transport name.</div>
        </div>
        <div class="form-group col text-center mb-5">
            <!-- <label>Carrier name</label> -->
            <input id="carrier_name" name="carrier_name" type="text" class="form-control" formControlName="carrier_name"
                style="margin: 0 auto;" placeholder="Enter a carrier name"
                [ngClass]="{'is-invalid': form.get('carrier_name').touched && form.get('carrier_name').invalid}">
            <div class="invalid-feedback">Enter a carrier name.</div>
        </div>

        <hr class="my-4">
        <p class="lead mb-0">
            Schedule
        </p>
        <p class="text-muted">
            Set up a schedule that either {{ form.get('schedule_type').value === 'pre' ? 'subtracts' : 'adds' }} a fixed
            number of days {{
            form.get('schedule_type').value === 'pre' ? 'from' : 'to' }} the
            schedule reference date. Or one that works based on a departure day schedule and calculates the correct day
            to leave at the {{ form.get('schedule_type').value === 'pre' ? 'place of receipt' : 'port of discharge' }}.
        </p>
        <div class="form-row">
            <div class="form-group col">
                <select id="schedule_mode" name="schedule_mode" class="form-control mw-100"
                    formControlName="schedule_mode">
                    <option [ngValue]="'fixed'">{{ form.get('schedule_type').value === 'pre' ? 'Subtract' : 'Add' }}
                    </option>
                    <option [ngValue]="'schedule'">Departs every</option>
                </select>
            </div>


            <div class="form-group col" *ngIf="form.get('schedule_mode').value === 'schedule'">
                <ng-select id="dep_days" formControlName="dep_days" [items]="departureDays"
                    bindLabel="label" bindValue="value" [multiple]="true" [closeOnSelect]="false"
                    [ngClass]="{'is-invalid': form.get('dep_days').touched && form.get('dep_days').invalid}">
                </ng-select>
                <div class="validation-error" *ngIf="form.get('dep_days').invalid && form.get('dep_days').touched">
                    Select at least one day.
                </div>
            </div>
            <span class="mx-1 schedule-inline-label" *ngIf="form.get('schedule_mode').value==='schedule'">and
                takes</span>
            <div class="form-group col col-md-2">
                <input id="time" name="time" class="form-control mw-100" type="number" placeholder="Days" min="0"
                    step="1" formControlName="time"
                    [ngClass]="{'is-invalid': form.get('time').touched && form.get('time').invalid}" />
                <div class="invalid-feedback">Enter a transit time.</div>
            </div>
            <div class="form-group col">
                <select id="time_days" name="time_days" class="form-control mw-100" formControlName="time_days"
                    [ngClass]="{'is-invalid': form.get('time_days').touched && form.get('time_days').invalid}">
                    <option [ngValue]="'cal'">calendar days</option>
                    <option [ngValue]="'wd_mf'">working days (Mon-Fri)</option>
                    <option [ngValue]="'wd_st'">working days (Sun-Thu)</option>
                </select>
                <div class="invalid-feedback">Define week days.</div>
            </div>
            <span class="mx-1  schedule-inline-label"
                *ngIf="form.get('schedule_mode').value==='fixed'">{{ form.get('schedule_type').value === 'pre' ? 'from' : 'to' }}</span>
            <div class="form-group col" *ngIf="form.get('schedule_mode').value==='fixed'">
                <select id="date" name="date" class="form-control mw-100" formControlName="date"
                    [ngClass]="{'is-invalid': form.get('date').touched && form.get('date').invalid}">
                    <option [ngValue]="'lrd'" *ngIf="form.get('schedule_type').value === 'pre'">closing</option>
                    <option [ngValue]="'etd'" *ngIf="form.get('schedule_type').value === 'pre'">ETD</option>
                    <option [ngValue]="'eta'" *ngIf="form.get('schedule_type').value === 'on'">ETA</option>
                </select>
                <div class="invalid-feedback">Select a reference date.</div>
            </div>
        </div>
        <hr class="my-4">
        <p class="lead mb-0">{{ form.get('schedule_type').value === 'pre' ? 'Destinations' : 'Origins' }}</p>
        <p class="text-muted">
            Filter to which {{ form.get('schedule_type').value === 'pre' ? 'destinations' : 'origins' }} schedule this
            carriage can be applied. This will help you route via the correct port. For example you might have 2
            precarriages out of Chicago, one to LA and one to NYC. You can filter the LA one to only be used for
            destinations in Asia, and the NYC one for destinations in Europe. Wildcards for all (*) and countries
            are possible.
        </p>
        <div class="form-group">
            <div>
                <button class="btn btn-sm btn-light destinations-button mr-1"
                    *ngFor="let d of (form.get('destinations').value || [])" (click)="removeDestination(d)">
                    <span *ngIf="d.includes('*')">
                        {{d.substr(0, d.length - 1)}}
                        <fa-icon [icon]="['far', 'asterisk']">
                        </fa-icon>
                    </span>
                    <span *ngIf="!d.includes('*')"> {{ d }}</span>
                    <fa-icon [icon]="['far', 'times']" class="ml-1 text-danger d-none float-right"></fa-icon>
                </button>

                <p-autoComplete id="destinations_select" name="destinations_select" [(ngModel)]="tempLocation"
                    [ngModelOptions]="{standalone: true}" [forceSelection]="true" class="mt-1"
                    [suggestions]="searchLocationResults" optionLabel="display_name" (completeMethod)="searchLocation($event)"
                    inputStyleClass="form-control form-control-sm" [required]="true"
                    (onSelect)="onLocationSelect($event.value, 'destinations')">
                    <ng-template let-value pTemplate="item">
                        <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight"></div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="validation-error" *ngIf="form.get('destinations').invalid && form.get('destinations').touched">
                Select at least one {{ form.get('schedule_type').value === 'pre' ? 'destination' : 'origin' }}.
            </div>
        </div>
        <hr class="my-4">
        <ng-container *ngIf="errorSaving$ | async; let saveError;">
            <div [@fadeIn] class="alert alert-danger mt-4" *ngIf="saveError?.status !== 422">
                <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1"></fa-icon>
                There was an error saving, please try again or contact support if the problem persists.
                <i>Status:
                    {{saveError.status}}</i>
            </div>
            <div [@fadeIn] class="alert alert-warning mt-4" role="alert" *ngIf="saveError?.status === 422">
                <h6 class="alert-heading">
                    <fa-icon [icon]="['far', 'exclamation-triangle']" class="mr-1"></fa-icon> There were
                    validation
                    errors
                </h6>
                <hr class="mt-0">
                <ul class="mb-0">
                    <li *ngFor="let e of saveError?.error | objectToArray:true">{{ e }}</li>
                </ul>
            </div>
        </ng-container>
        <div class="form-group d-flex justify-content-between mt-5">
            <div>
                <button class="btn btn-primary mr-2" [disabled]="form.disabled">
                    <fa-icon [icon]="['far', 'spinner']" pulse="true" class="mr-1" *ngIf="saving$ | async"></fa-icon>
                    {{ (saving$ | async) ? 'Saving' : 'Save' }} carriage
                </button>
                <button class="btn btn-light" [disabled]="form.disabled" type="button"
                    [routerLink]="['../']">Cancel</button>
            </div>
            <button *ngIf="form.get('id').value" class="btn btn-outline-danger" [disabled]="form.disabled" type="button"
                (click)="deleteCarriage()">
                <fa-icon [icon]="['far', 'spinner']" pulse="true" class="mr-1" *ngIf="deleting$ | async"></fa-icon>
                {{ (deleting$ | async) ? 'Deleting' : 'Delete' }} carriage
            </button>
        </div>
    </form>
</div>
