import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { StoreModule } from '@ngrx/store';
import { TableModule } from 'primeng/table';
import { EffectsModule } from '@ngrx/effects';
import { DropdownModule } from 'primeng/dropdown';
import { TreeTableModule } from 'primeng/treetable';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectModule } from 'primeng/multiselect';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import {
    MD5Pipe,
    TraversePipe,
    UrlEncodePipe,
    SlugToTitlePipe,
    FormErrorComponent,
    AddressFormComponent,
    UserInfoTooltipComponent,
    LoadingIndicatorComponent,
    ControlledByMagayaWarningComponent,
} from '@qwyk/ui-standalone';

import {
    CustomerUserCreateComponent,
    CustomerUserDetailComponent,
    CustomerUserImpersonateModalComponent,
    CustomerUserPermissionsComponent,
    CustomerUsersListComponent,
    CustomerUsersListWidgetComponent,
    CustomerUserViewComponent,
    SearchMagayaEntityListComponent,
    SearchMagayaEntityModalComponent,
} from './components';

import * as fromCustomerUsers from './+state/customer-users.reducer';
import { CustomerUsersFacade } from './+state/customer-users.facade';
import { CustomerUsersEffects } from './+state/customer-users.effects';
import { CustomerUserStatusBadgeComponent } from './components/customer-user-status-badge/customer-user-status-badge.component';

@NgModule({
    imports: [
        CommonModule,
        MD5Pipe,
        NgbModule,
        TableModule,
        FormsModule,
        RouterModule,
        MomentModule,
        TraversePipe,
        UrlEncodePipe,
        NgSelectModule,
        DropdownModule,
        SlugToTitlePipe,
        TreeTableModule,
        TranslateModule,
        FontAwesomeModule,
        MultiSelectModule,
        FormErrorComponent,
        ReactiveFormsModule,
        AddressFormComponent,
        UserInfoTooltipComponent,
        ReactiveValidationModule,
        LoadingIndicatorComponent,
        ControlledByMagayaWarningComponent,
        StoreModule.forFeature(
            fromCustomerUsers.CUSTOMERUSERS_FEATURE_KEY,
            fromCustomerUsers.reducer
        ),
        EffectsModule.forFeature([CustomerUsersEffects]),
    ],
    providers: [CustomerUsersFacade],
    declarations: [
        CustomerUsersListComponent,
        CustomerUserViewComponent,
        CustomerUserDetailComponent,
        CustomerUsersListWidgetComponent,
        CustomerUserCreateComponent,
        CustomerUserImpersonateModalComponent,
        CustomerUserStatusBadgeComponent,
        SearchMagayaEntityModalComponent,
        SearchMagayaEntityListComponent,
        CustomerUserPermissionsComponent,
    ],
    exports: [CustomerUsersListWidgetComponent],
})
export class HubAdministrationCustomerUsersWithoutRoutingModule {}
