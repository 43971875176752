import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CanComponentDeactivate, MasterDataService } from '@qwyk/core';
import { tap } from 'rxjs/operators';
import { CustomerUsersService } from '../../services/customer-users.service';
import { Validators } from 'angular-reactive-validation';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'qwyk-customer-user-create',
    templateUrl: './customer-user-create.component.html',
    styleUrls: ['./customer-user-create.component.scss'],
})
export class CustomerUserCreateComponent
    implements CanComponentDeactivate, OnDestroy
{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() public error: any;
    @Output()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public save: EventEmitter<any> = new EventEmitter();
    public form: FormGroup = this.fb.group({
        email: [
            null,
            [
                Validators.required(
                    this.translate.instant(
                        'common.validation.enter-an-email-address'
                    )
                ),
                Validators.email(
                    this.translate.instant(
                        'common.validation.enter-a-valid-email-address'
                    )
                ),
                Validators.maxLength(191, (maxLength) =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ],
        ],
        password: [
            { value: this.generateRandomPassword(), disabled: true },
            [
                Validators.required(
                    this.translate.instant('common.validation.enter-a-password')
                ),
                Validators.minLength(12, minLength =>
                    this.translate.instant('common.validation.minLength', {
                        field: this.translate
                            .instant('common.password')
                            .toLowerCase(),
                        minLength,
                    })
                ),
                Validators.maxLength(36, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength }
                    )
                ),
                Validators.pattern(
                    /^[a-zA-Z0-9!@#$%&\-.*=]+$/,
                    this.translate.instant(
                        'common.validation.pattern',
                        this.translate.instant('common.password').toLowerCase()
                    )
                ),
            ],
        ],
        team_id: [
            null,
            [
                Validators.required(
                    this.translate.instant(
                        'hub.administration.portal-users.select-a-customer-from-the-list'
                    )
                ),
            ],
        ],
        registered_site_id: [
            null,
            [
                Validators.required(
                    this.translate.instant(
                        'hub.administration.portal-users.select-a-site-from-the-list'
                    )
                ),
            ],
        ],
        restrict_to_registered_site: [false],
        name: [
            null,
            [
                Validators.required(
                    this.translate.instant(
                        'hub.administration.portal-users.enter-a-name'
                    )
                ),
                Validators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ],
        ],
        company_name: [
            null,
            [
                Validators.required(
                    this.translate.instant(
                        'hub.administration.portal-users.enter-a-company-name'
                    )
                ),
                Validators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ],
        ],
        job_title: [
            null,
            [
                Validators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ],
        ],
        location: [
            null,
            [
                Validators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ],
        ],
        country_code: [null],
        phone_number: [
            null,
            [
                Validators.maxLength(15, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ],
        ],
        marketing_optin: false,
        privacy_compliance: [
            false,
            [
                Validators.requiredTrue(
                    this.translate.instant(
                        'hub.administration.portal-users.you-must-confirm-this-action'
                    )
                ),
            ],
        ],
    });
    public loadingTeams = true;
    public teams$ = this.service.getTeams({ pageSize: 1000000 }).pipe(
        tap(teams => {
            this.loadingTeams = false;
            if (
                teams &&
                teams.length === 1 &&
                !this.form.get('team_id').value
            ) {
                this.form.get('team_id').setValue(teams[0].id);
                this.form.get('company_name').setValue(teams[0].name);
            }
        })
    );
    public loadingSites = true;
    public sites$ = this.service
        .getSites({ pageSize: 100000, active: true })
        .pipe(
            tap(sites => {
                this.loadingSites = false;
                if (
                    sites &&
                    sites.length === 1 &&
                    !this.form.get('registered_site_id').value
                ) {
                    this.form.get('registered_site_id').setValue(sites[0].id);
                }
            })
        );
    public loadingCountries = false;
    public countries$ = this.masterData
        .getCountries()
        .pipe(tap(() => (this.loadingCountries = false)));
    public complianceTooltipText = this.translate.instant(
        'hub.administration.portal-users.compliance-tooltip-text'
    );
    private paramsSubscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private masterData: MasterDataService,
        private service: CustomerUsersService
    ) {
        this.paramsSubscription = this.route.queryParamMap.subscribe(params => {
            if (
                params.has('team_id') &&
                this.form.get('team_id') &&
                !this.form.get('team_id').value
            ) {
                this.form.get('team_id').setValue(params.get('team_id'));
            }
        });
    }

    private _saving: boolean;

    public get saving(): boolean {
        return this._saving;
    }

    @Input()
    public set saving(saving: boolean) {
        this._saving = saving;
        setTimeout(() => {
            if (saving) {
                this.form.disable();
            } else {
                this.form.enable();
                this.form.get('password').disable();
            }
        }, 0);
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

    public onCreate() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        this.form.markAsPristine();
        this.save.emit(this.form.getRawValue());
    }

    public onSetRandomPassword() {
        this.form.get('password').setValue(this.generateRandomPassword());
    }

    public canDeactivate() {
        if (this.form.dirty) {
            return confirm(
                this.translate.instant(
                    'portal.settings.profile.unsaved-changes'
                )
            );
        }
        return true;
    }

    public onTeamSelected(team) {
        if (team) {
            if (!this.form.get('company_name').touched && team.name) {
                this.form.get('company_name').setValue(team.name);
            }
            if (!this.form.get('location').touched) {
                this.form.get('location').setValue(team.city);
            }
            if (!this.form.get('country_code').touched) {
                this.form.get('country_code').setValue(team.country_code);
            }
        }
    }

    private generateRandomPassword(minLength = 12, maxLength = 18): string {
        const length =
            minLength + Math.floor((maxLength - minLength + 1) * Math.random());
        const chars =
            'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ0123456789!!@@##$$';
        let pwd = '';
        for (let i = 0; i < length; i++) {
            let newChar = null;
            while (newChar === null || pwd.includes(newChar)) {
                newChar = chars[Math.floor(Math.random() * chars.length)];
            }
            pwd += newChar;
        }

        return pwd;
    }
}
