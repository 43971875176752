/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
    tap,
    take,
    switchMap,
    catchError,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';
import * as moment from 'moment';
import { concat, Observable, of, Subject } from 'rxjs';

import {
    Constants,
    QwykValidators,
    MasterDataService,
    AlgoliaLocationsService,
} from '@qwyk/core';
import { Animations } from '@qwyk/ui-standalone';
import { masterdata, ui, Portals } from '@qwyk/models';
import { QuotationsFacade } from '@qwyk/shared-stores/quotations';

import { QuotationsService } from '../../services/quotations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'qwyk-quotation-create',
    templateUrl: './quotation-create.component.html',
    styleUrls: ['./quotation-create.component.scss'],
    animations: [Animations.fadeInAnimation],
})
export class QuotationCreateComponent implements OnInit {
    public loadTypes = [
        { value: 'fcl', label: 'common.load-types.full-load' },
        { value: 'lcl', label: 'common.load-types.partial-load' },
    ];
    public transportModes = {
        fcl: [
            { value: 'OCEAN', label: 'common.transport-modes.ocean' },
            { value: 'TRUCK', label: 'common.transport-modes.truck' },
        ],
        lcl: [
            { value: 'AIR', label: 'common.transport-modes.air' },
            { value: 'OCEAN', label: 'common.transport-modes.ocean' },
            { value: 'TRUCK', label: 'common.transport-modes.truck' },
        ],
    };

    public currencies$ = this.masterdataService.getCurrencies();
    public commodities$ = this.masterdataService.getCommodities();
    public incoTerms = Constants.INCO_TERMS;
    public packagings$: Observable<any[]>;
    public minCargoReadyDate = moment().toDate();
    public isSearchingLocations: boolean;
    public locationsSuggestions: any[];
    public headerCompleted = false;
    public customers$: Observable<any[]>;
    public customersLoading = false;
    public customerInput$ = new Subject<string>();
    public form: FormGroup;
    public autorate = true;
    public dimensionsInput = true;
    public error = null;
    public busy = false;
    public autorating = false;
    public dateFormat: string;
    public cargoreadyPlaceholder: string;
    private networkedOrganizations: any[];
    public networkedOrganizations$ = this.service
        .getNetworkedOrganizations()
        .pipe(tap(data => (this.networkedOrganizations = data)));
    public transportModes$ = this.masterdataService.getTransportModeLoadType(
        'OCEAN',
        'fcl'
    );

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private service: QuotationsService,
        private translate: TranslateService,
        private quotations: QuotationsFacade,
        private algolia: AlgoliaLocationsService,
        private masterdataService: MasterDataService
    ) {
        this.form = this.fb.group({
            header: this.fb.group({
                owner_id: [null],
                is_network_quotation: [false],
                organization_network_authorizations_id: [
                    null,
                    [
                        QwykValidators.requiredIfOtherValidator(
                            'is_network_quotation',
                            true
                        ),
                    ],
                ],
                include_own_rates: [false],
                load_type: [null, [Validators.required]],
                transport_mode: [null, [Validators.required]],
                origin: [null, [Validators.required]],
                origin_type: ['port', [Validators.required]],
                origin_address: [
                    null,
                    [
                        QwykValidators.requiredIfOtherValidator(
                            'origin_type',
                            'place'
                        ),
                    ],
                ],
                destination: [null, [Validators.required]],
                destination_type: ['port', [Validators.required]],
                destination_address: [
                    null,
                    [
                        QwykValidators.requiredIfOtherValidator(
                            'destination_type',
                            'place'
                        ),
                    ],
                ],
                require_origin_charges: [false, [Validators.required]],
                require_destination_charges: [false, [Validators.required]],
                require_freight_charges: [true, [Validators.required]],
            }),
            commodity: [null, [Validators.required]],
            inco_term: [null],
            cargo_ready: [new Date(), [Validators.required]],
            currency: [null, [Validators.required]],
            owner_reference: [null, [Validators.maxLength(255)]],
            packages: this.fb.array([], [Validators.minLength(1)]),
            additionals: this.fb.group({
                customs_clearance_origin: [false],
                customs_clearance_destination: [false],
                cargo_insurance: [false],
                hazardous_cargo: [false],
                liftgate_pickup: [false],
                refrigerated: [false],
                white_glove: [false],
                residential_delivery: [false],
                residential_pickup: [false],
                liftgate_delivery: [false],
                call_before_delivery: [false],
                delivery_appointment_required: [false],
                flatbed_required: [false],
                limited_access_delivery: [false],
                limited_access_pickup: [false],
                stackable: [false],
                overweight: [false],
            }),
        });

        this.dateFormat = `${this.translate.instant(
            'common.primeng.dateFormat'
        )}yy`;
        this.cargoreadyPlaceholder = `${this.dateFormat} hh:mm`;
    }

    get getCargoFormArray() {
        return this.form.get('packages') as FormArray;
    }

    ngOnInit(): void {
        this.loadCustomers();
        this.customerInput$.next(' ');
        if (this.route.snapshot.queryParamMap.has('copy')) {
            this.upsertFromQuotation(this.route.snapshot.paramMap.get('id'));
        }
    }

    public createQuotation() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        this.error = null;
        this.busy = true;
        this.form.disable();
        const value = this.form.getRawValue();

        let packages = value.packages;
        if (value.header.load_type === 'lcl') {
            if (this.dimensionsInput) {
                packages = packages.map(e => {
                    const cubix = e.unit_height * e.unit_width * e.unit_length;
                    if (e.uom === 'metric') {
                        e.unit_volume = Math.round((cubix / 1e6) * 1000) / 1000;
                    } else {
                        e.unit_volume =
                            Math.round((cubix / 1728) * 1000) / 1000;
                    }

                    return e;
                });
            } else {
                packages = packages.map(e => {
                    let volume = e.unit_volume;
                    if (e.uom === 'metric') {
                        volume *= 1e6;
                    } else {
                        volume *= 1728;
                    }
                    volume = Math.round(Math.cbrt(volume) * 1000) / 1000;

                    e.unit_length = volume;
                    e.unit_width = volume;
                    e.unit_height = volume;

                    return e;
                });
            }
        }

        let originCode = value.header.origin.locode;
        let originName = value.header.origin.display_name;
        let originPostalCode = null;
        let originCityName = null;
        let originState = null;
        if (value.header.origin_type === 'place') {
            originCode = `G:${value.header.origin_address.placeId}|X:${
                value.header.origin_address.position?.lng
            }|Y:${value.header.origin_address.position?.lat}|C:${
                value.header.origin_address.countryCode
            }|P:${value.header.origin_address.postalCode || ''}`;

            originName = value.header.origin_address.formattedAddress;
            originPostalCode = value.header.origin_address.postalCode;
            originCityName = value.header.origin_address.locality;
            originState = value.header.origin_address.state;
        }

        let destinationCode = value.header.destination.locode;
        let destinationName = value.header.destination.display_name;
        let destinationPostalCode = null;
        let destinationCityName = null;
        let destinationState = null;
        if (value.header.destination_type === 'place') {
            destinationCode = `G:${value.header.destination.placeId}|X:${
                value.header.destination_address.position?.lng
            }|Y:${value.header.destination_address.position?.lat}|C:${
                value.header.destination_address.countryCode
            }|P:${value.header.destination_address.postalCode || ''}`;

            destinationName = value.header.destination_address.formattedAddress;
            destinationPostalCode = value.header.destination_address.postalCode;
            destinationCityName = value.header.destination_address.locality;
            destinationState = value.header.destination_address.state;
        }

        const payload = {
            owner_id: value.header.owner_id,
            load_type: value.header.load_type,
            transport_mode: value.header.transport_mode,
            origin: {
                type: value.header.origin_type,
                code: originCode,
                locode: value.header.origin.locode,
                name: originName,
                country_code: value.header.origin.country_code,
                postal_code: originPostalCode,
                city_name: originCityName,
                state: originState,
            },
            destination: {
                type: value.header.destination_type,
                code: destinationCode,
                locode: value.header.destination.locode,
                name: destinationName,
                country_code: value.header.destination.country_code,
                postal_code: destinationPostalCode,
                city_name: destinationCityName,
                state: destinationState,
            },
            require_origin_charges:
                value.header.origin_type === 'place' ||
                value.header.require_origin_charges,
            require_freight_charges: value.header.require_freight_charges,
            require_destination_charges:
                value.header.destination_type === 'place' ||
                value.header.require_destination_charges,
            commodity: value.commodity,
            inco_term: value.inco_term,
            cargo_ready: value.cargo_ready,
            currency: value.currency,
            owner_reference: value.owner_reference,
            packages: packages.map(e => {
                e.id = 0;
                return e;
            }),
            additionals: value.additionals,
            is_network_quotation: value.header.is_network_quotation,
            organization_network_authorizations_id: value.header
                .is_network_quotation
                ? value.header.organization_network_authorizations_id
                : null,
            include_own_rates: value.header.is_network_quotation
                ? value.header.include_own_rates
                : null,
        };

        this.service
            .createQuotation(payload, this.autorate)
            .pipe(take(1))
            .subscribe(
                quotation => {
                    this.router.navigate(
                        this.autorate
                            ? ['/', 'quotations', quotation.id]
                            : [
                                  '/',
                                  'quotations',
                                  quotation.id,
                                  'offers',
                                  'new',
                              ],
                        {
                            relativeTo: this.route,
                            queryParams: this.autorate ? { poll: true } : {},
                        }
                    );
                },
                error => {
                    this.form.enable();
                    this.busy = false;
                    this.error = error;
                }
            );
    }

    completeHeader(packages?: Portals.QuotationPackage[]) {
        if (this.form.get('header').invalid) {
            return;
        }
        const loadType = this.form.get('header.load_type').value;
        this.packagings$ = this.masterdataService.getPackagings(
            this.form.get('header.transport_mode').value,
            loadType,
            true
        );
        this.getCargoFormArray.clear();
        if (packages?.length) {
            packages.forEach(() => {
                this.getCargoFormArray.push(
                    loadType === 'fcl'
                        ? this.makeFclCargoRow()
                        : this.makeLclCargoRow()
                );
            });
        } else {
            this.getCargoFormArray.push(
                loadType === 'fcl'
                    ? this.makeFclCargoRow()
                    : this.makeLclCargoRow()
            );
        }
        this.headerCompleted = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onLoadTypeChanged(_e) {
        if (this.form.get('header.transport_mode').value === 'AIR') {
            this.form.get('header.transport_mode').reset();
        }
    }

    getTransportMode(): string {
        return this.form.get('header.transport_mode').value;
    }

    isOceanTransport(): boolean {
        return this.getTransportMode() === 'OCEAN';
    }

    onUOMSet(index: number, value: any) {
        const control = this.form.get(`packages.${index}.uom`);
        if (value) {
            control.setValue(value.key);
        } else {
            control.setValue(null);
        }
    }

    onVolumeChange(index: number, event): void {
        const control = this.form.get(`packages.${index}`);
        const value = event.target.value;
        let dim = value;
        if (value) {
            if (control.get('uom').value !== 'imperial') {
                dim *= 1e6;
            } else {
                dim *= 1728;
            }
            dim = Math.round(Math.cbrt(dim) * 1000) / 1000;
        }

        ['unit_length', 'unit_width', 'unit_height'].forEach(ctrl => {
            control.get(ctrl).setValue(dim);
        });
    }

    public onLocationsAutocomplete(event: string): void {
        if (event) {
            this.isSearchingLocations = true;
            const transportMode = this.form.get('header.transport_mode').value;
            let prefer = [];
            if (transportMode === 'AIR') {
                prefer = ['is_major_airport:true', 'is_airport:true'];
            } else if (transportMode === 'OCEAN') {
                prefer = ['is_major_port:true', 'is_port:true'];
            }
            const subscr = this.algolia
                .getLocationSuggestions(event, prefer)
                .subscribe(
                    result => {
                        this.locationsSuggestions = result.slice(0, 5);
                        this.isSearchingLocations = false;
                        subscr.unsubscribe();
                    },
                    () => {
                        this.isSearchingLocations = false;
                        subscr.unsubscribe();
                    }
                );
        } else {
            this.locationsSuggestions = null;
        }
    }

    public addCargoRow() {
        (this.form.get('packages') as FormArray).push(this.makeLclCargoRow());
    }

    public removeCargoRow(idx: number) {
        const arr = this.form.get('packages') as FormArray;
        if (arr.length > 1) {
            arr.removeAt(idx);
        }
    }

    public currencySearchFn(term: string, item: masterdata.Currency) {
        term = term.toLowerCase();
        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            item.code.toLowerCase().indexOf(term) > -1
        );
    }

    public onPlaceAutocomplete(
        event: ui.GooglePlacesAddress,
        control: 'header.origin_address' | 'header.destination_address'
    ) {
        this.form.get(control).patchValue(event);
    }

    public onIsNetworkQuotationChanged(e) {
        if (!e.target.checked) {
            return;
        }

        if (!this.networkedOrganizations) {
            return;
        }

        const originValue = this.form.get('header.origin').value;
        const destinationValue = this.form.get('header.destination').value;

        if (!originValue && !destinationValue) {
            return;
        }
        let found = null;
        for (const location of [originValue, destinationValue]) {
            if (!location.country_code) {
                continue;
            }

            found = this.networkedOrganizations.find(
                e =>
                    e &&
                    e.country &&
                    e.country.iso_3166_2 === location.country_code
            );

            if (found) {
                break;
            }
        }

        if (found) {
            this.form
                .get('header.organization_network_authorizations_id')
                .setValue(found.id);
        }
    }

    private upsertFromQuotation(fromQuotationId: string): void {
        this.quotations.selectQuotation(fromQuotationId);

        this.quotations.selectedQuotations$
            .pipe(take(1))
            .subscribe(quotation => {
                if (!quotation) {
                    return;
                }
                this.loadCustomers(quotation.owner.email);
                this.form.patchValue({
                    header: {
                        owner_id: quotation.owner.id,
                        load_type: quotation.request.load_type,
                        transport_mode: quotation.request.transport_mode,
                        origin_type: quotation.request.origin.type,
                        origin: {
                            locode: quotation.request.origin.locode,
                            display_name: quotation.request.origin.name,
                            country_code: quotation.request.origin.country_code,
                        },
                        destination_type: quotation.request.destination.type,
                        destination: {
                            locode: quotation.request.destination.locode,
                            display_name: quotation.request.destination.name,
                            country_code:
                                quotation.request.destination.country_code,
                        },
                        origin_address:
                            quotation.request.origin.type === 'place'
                                ? this.constructAddressForUpsert(
                                      quotation.request.origin
                                  )
                                : null,
                        destination_address:
                            quotation.request.destination.type === 'place'
                                ? this.constructAddressForUpsert(
                                      quotation.request.destination
                                  )
                                : null,
                        require_origin_charges:
                            quotation.request.origin.type === 'place'
                                ? true
                                : quotation.request.require_origin_charges,
                        require_freight_charges:
                            quotation.request.require_freight_charges,
                        require_destination_charges:
                            quotation.request.destination.type === 'place'
                                ? true
                                : quotation.request.require_destination_charges,
                    },
                });
                this.completeHeader(quotation.request?.packages || []);
                const packages = quotation.request.packages.map(p => ({
                    ...p,
                    container_type: p.container_type_code,
                }));

                this.form.patchValue({
                    inco_term: quotation.request.inco_term,
                    commodity: quotation.request.commodity,
                    currency: quotation.request.currency,
                    packages,
                    additionals: quotation.request.additionals,
                });
            });
    }

    private constructAddressForUpsert(location) {
        const splitCode = location.code.split('|');
        const obj = { P: null, C: null, X: null, Y: null, G: null };
        for (const el of splitCode) {
            const split = el.split(':');
            obj[split[0]] = split[1];
        }

        return {
            formattedAddress: location.name,
            streetNumber: null,
            route: null,
            locality: null,
            postalCode: obj['P'],
            state: null,
            countryName: null,
            countryCode: obj['C'],
            position: {
                lat: obj['Y'],
                lng: obj['X'],
            },
            types: [],
            code: obj['P'],
            placeId: obj['G'],
            plusCode: null,
        };
    }

    private loadCustomers(customer = null) {
        this.customers$ = concat(
            this.service.findUser(customer),
            this.customerInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => (this.customersLoading = true)),
                switchMap(term =>
                    this.service.findUser(term).pipe(
                        catchError(() => of([])),
                        tap(() => (this.customersLoading = false))
                    )
                )
            )
        );
    }

    private makeFclCargoRow(): FormGroup {
        return this.fb.group({
            quantity: [1, [Validators.required, Validators.min(1)]],
            packaging: [null, [Validators.required]],
            unit_weight: [null],
            unit_volume: [null],
            unit_width: [null],
            unit_length: [null],
            unit_height: [null],
            uom: [null],
            container_type: [null],
        });
    }

    private makeLclCargoRow(): FormGroup {
        return this.fb.group({
            quantity: [1, [Validators.required, Validators.min(1)]],
            packaging: [null, [Validators.required]],
            unit_weight: [null, [Validators.required, Validators.min(0.1)]],
            unit_volume: [null, Validators.max(100000)],
            unit_length: [
                null,
                [Validators.required, Validators.min(1), Validators.max(10000)],
            ],
            unit_width: [
                null,
                [Validators.required, Validators.min(1), Validators.max(10000)],
            ],
            unit_height: [
                null,
                [Validators.required, Validators.min(1), Validators.max(10000)],
            ],
            uom: ['metric', [Validators.required]],
        });
    }
}
