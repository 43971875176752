/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PRODUCT_ICONS } from './../../../../../constants/productMappings';
import { RoutingGuideService } from './../../../services/routing-guide.service';
import { AlgoliaLocationsService } from './../../../../shared/services/algolia-locations.service';

@Component({
    selector: 'app-simulate-routing-guide',
    templateUrl: './simulate-routing-guide.component.html',
    styleUrls: ['./simulate-routing-guide.component.scss'],
})
export class SimulateRoutingGuideComponent {
    public simulateForm: FormGroup;
    public searchLocationResults: any[];
    public result$: Observable<any> = of([]);
    public productIcons = PRODUCT_ICONS;

    constructor(
        private fb: FormBuilder,
        private service: RoutingGuideService,
        private algoliaLocations: AlgoliaLocationsService
    ) {
        this.simulateForm = this.fb.group({
            origin: [null, [Validators.required]],
            destination: [null, [Validators.required]],
            product: [null, [Validators.required]],
        });
    }

    simulate() {
        if (this.simulateForm.invalid) {
            return;
        }

        const formVal = this.simulateForm.value;

        this.result$ = this.service
            .simulateRoutingGuide({
                product: formVal.product,
                origin: formVal.origin.locode,
                destination: formVal.destination.locode,
            })
            .pipe(catchError(err => of(err)));
    }

    /**
     * Handles searching for locations
     * @param event the event emitted by the typeahead
     */
    searchLocation(event) {
        const searchSubscription = this.algoliaLocations
            .getLocationSuggestions(event.query)
            .subscribe(results => {
                this.searchLocationResults = results;
                searchSubscription.unsubscribe();
            });
    }

    onLocationSelect(event, formControlName) {
        this.simulateForm.get(formControlName)?.setValue({
            locode: event.locode,
            display_name: event.display_name,
        });
    }

    onLocationClear(event, formControlName) {
        this.simulateForm.get(formControlName)?.patchValue(null);
    }

    /**
     * Initializes the dialog with a product
     * @param product the product to set
     */
    public setProduct(product: string): void {
        this.simulateForm.get('product')?.setValue(product);
    }
}
