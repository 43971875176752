<form [formGroup]="form" (submit)="onSubmit()">
    <div class="form-group">
        <label for="name">{{ 'common.name' | translate }}</label>
        <input type="text" id="name" name="name" formControlName="name" class="form-control" placeholder="{{ 'common.name' | translate }}"
            maxlength="191" [ngClass]="{'is-invalid': form.get('name').touched && form.get('name').invalid}" />
        <arv-validation-messages for="name"></arv-validation-messages>
    </div>

    <div class="form-group">
        <label for="email">{{ 'common.email' | translate }}</label>
        <input id="email" name="email" type="email" [value]="user.email" class="form-control" disabled />
    </div>

    <div class="form-group">
        <label for="role_id">{{ 'hub.menu.top-menu.role' | translate }}</label>
        <ng-select [items]="roles$ | async" id="role_id" formControlName="role_id" bindLabel="name"
            bindValue="id" [ngClass]="{'is-invalid': form.get('role_id').touched && form.get('role_id').invalid}"
            [loading]="rolesLoading" [readonly]="rolesLoading">
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <div>{{item.name}}</div>
                <small class="text-muted">{{ item.description }}</small>
            </ng-template>
        </ng-select>
        <arv-validation-messages for="role_id"></arv-validation-messages>
    </div>

    <qwyk-form-error [error]="error"></qwyk-form-error>

    <div class="form-group border-top pt-3 text-right">
        <button class="btn btn-light" type="button" [routerLink]="['..']" [disabled]="form.disabled">{{ 'common.cancel' | translate}}</button>
        <button class="btn btn-primary ml-2" type="submit" [disabled]="form.disabled">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" *ngIf="form.disabled" class="mr-2"></fa-icon>
            <fa-icon [icon]="['far', 'pencil']" *ngIf="!form.disabled" class="mr-2"></fa-icon>
            {{ 'hub.settings.users.update-user' | translate }}
        </button>
        <button class="btn btn-danger ml-2" type="button" (click)="onDeactivate()" *ngIf="canUpdateUserStatus() && user.active">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" *ngIf="form.disabled" class="mr-2"></fa-icon>
            <fa-icon [icon]="['far', 'user-times']" *ngIf="!form.disabled" class="mr-2"></fa-icon>
            {{ 'hub.administration.portal-users.deactivate-user' | translate }}
        </button>
        <button class="btn btn-success ml-2" type="button" (click)="onActivate()" *ngIf="canUpdateUserStatus() && !user.active">
            <fa-icon [icon]="['far', 'spinner']" [pulse]="true" *ngIf="form.disabled" class="mr-2"></fa-icon>
            <fa-icon [icon]="['far', 'user-plus']" *ngIf="!form.disabled" class="mr-2"></fa-icon>
            {{ 'hub.settings.users.activate-user' | translate }}
        </button>
    </div>
</form>
