<div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 p-0" [@fadeInOut]>
  <div class="head mt-3 mb-5 d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <fa-icon [icon]="['fad', 'ship']" size="2x" class="text-primary mr-3" fixedWidth="true"></fa-icon>
      <div>
        <span class="lead">Providers</span>
        <p class="text-muted mb-0">View and configure which providers you can use in your schedules.</p>
      </div>
    </div>
  </div>

  <div>
    <div class="d-flex justify-content-between mb-5">
      <form [formGroup]="filterForm" class="form-inline">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Name..." formControlName="name" />
        </div>
        <div class="form-group mx-sm-3">
          <select class="custom-select" formControlName="product"
            [ngClass]="{'text-muted': filterForm.get('product').value === ''}">
            <option value="">Select...</option>
            <option value="air">Air</option>
            <option value="fcl">FCL</option>
            <option value="lcl">LCL</option>
            <option value="csl">Consol</option>
          </select>
        </div>
      </form>
      <div>
        <button class="btn btn-primary" (click)="openCreateProviderModal()">Create custom provider</button>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <div class="text-muted border-bottom d-flex justify-content-between">
          <span class="strong">AVAILABLE</span>
          <span>{{ inactiveProviders?.length || 0 }}</span>
        </div>
        <div
          *ngFor="let p of inactiveProviders | slice: (paging.inactivePage-1) * paging.pageSize : (paging.inactivePage-1) * paging.pageSize + paging.pageSize"
          class="provider-item d-flex align-items-center p-2 border-bottom bg-light shadow" [@fadeIn]>
          <ng-container *ngTemplateOutlet="providerTemplate, context: {$implicit: p}"></ng-container>
          <div class="actions d-flex ml-1">
            <button class="btn btn-danger mr-1 btn-sm rounded-circle shadow-sm" *ngIf="!p.is_oob" (click)="delete(p)">
              <fa-icon [icon]="['far', 'trash']"></fa-icon>
            </button>
            <button class="btn btn-success btn-sm rounded-circle shadow-sm" (click)="activate(p)">
              <fa-icon [icon]="['far', 'plus']"></fa-icon>
            </button>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="state, context: {$implicit: inactiveProviders}"></ng-container>
        <ngb-pagination [(page)]="paging.inactivePage" class="d-flex justify-content-center mt-2"
          [pageSize]="paging.pageSize" [collectionSize]="inactiveProviders.length" size="sm"></ngb-pagination>

      </div>
      <div class="col-6">
        <div class="text-muted border-bottom d-flex justify-content-between">
          <span class="strong">ACTIVE</span>
          <span>{{ activeProviders?.length || 0 }}</span>
        </div>
        <div
          *ngFor="let p of activeProviders | slice: (paging.activePage-1) * paging.pageSize : (paging.activePage-1) * paging.pageSize + paging.pageSize;"
          class="provider-item d-flex align-items-center p-2 border-bottom bg-light shadow" [@fadeIn]>
          <ng-container *ngTemplateOutlet="providerTemplate, context: {$implicit: p}"></ng-container>
          <div class="actions d-flex ml-1">
            <button class="btn btn-danger btn-sm rounded-circle shadow-sm" (click)="deactivate(p)">
              <fa-icon [icon]="['far', 'minus']"></fa-icon>
            </button>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="state; context: {$implicit: activeProviders}"></ng-container>
        <!-- <div *ngIf="' class="" -->
        <ngb-pagination [(page)]=" paging.activePage" class="d-flex justify-content-center mt-2"
          [pageSize]="paging.pageSize" [collectionSize]="activeProviders.length" size="sm">
        </ngb-pagination>
      </div>
    </div>
  </div>

</div>

<ng-template #providerTemplate let-provider>
  <div class="d-flex align-items-center provider-item-inner no-select">
    <img [src]="provider.is_oob && provider.code[0] !== '$' ? 'https://cdn.qwyk.io/images/carriers/rect/' + provider.code?.toLowerCase() + '.png': ''"
      style="width: 36px; min-width: 36px;" class="mr-3" />
    <div style="min-width: 0;">
      <div class="mb-0 provider-name">{{ provider.name }}</div>
      <div class="text-muted">{{ provider.product | productTitle }}</div>
    </div>
  </div>
</ng-template>

<ng-template #state let-data>
  <div class="text-center p-3 text-muted">
    <div *ngIf="! isLoading && ! isError && ( ! data || data.length === 0 )">
      <fa-icon [icon]="['far', 'cloud']" size="2x"></fa-icon>
      <p>No providers here</p>
    </div>
    <div *ngIf="isLoading">
      <fa-icon [icon]="['far', 'spinner']" pulse="true" size="2x"></fa-icon>
    </div>
    <div *ngIf="isError" class="text-danger">
      <fa-icon [icon]="['far', 'exclamation-triangle']" size="2x"></fa-icon>
      <p>There was an error, please reload the page, or contact support if the problem persists.</p>
    </div>
  </div>
</ng-template>
