import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import {
    ActivitiesServiceBase,
    SharedStoresActivitiesModule,
} from '@qwyk/shared-stores/activities';
import { ActivitiesUiModule } from '@qwyk/activities-ui';
import { ExpandCollapseButtonComponent } from '@qwyk/ui-standalone';

import { ActivitiesService } from './activities.service';
import { components, exportedComponents } from './components';

@NgModule({
    imports: [
        CommonModule,
        NgbAlertModule,
        TranslateModule,
        FontAwesomeModule,
        NgbDropdownModule,
        ActivitiesUiModule,
        ExpandCollapseButtonComponent,
        SharedStoresActivitiesModule.forFeature({
            service: {
                provide: ActivitiesServiceBase,
                useFactory: (http: HttpClient) => new ActivitiesService(http),
                deps: [HttpClient],
            },
        }),
    ],
    declarations: components,
    exports: exportedComponents,
})
export class HubActivitiesModule {}
