<div class="bg-light min-vh-100">
    <div class="container py-3 pb-5">
        <div class="small text-muted border-bottom pb-2 mb-3">
            <a [routerLink]="['/']" class="text-muted">DFP</a>
            &rsaquo;
            <a [routerLink]="['/quotations/list']" class="text-muted">{{ 'hub.common.quotations' | translate }}</a>
            &rsaquo;
            <a [routerLink]="['./']" class="text-muted font-weight-bold">{{ 'common.new' | translate }}</a>
        </div>

        <div class="d-flex flex-row justify-content-between mb-3">
            <h3 class="font-weight-normal m-0">
                {{ 'common.create' | translate }} {{ 'common.quotations.quotation' | translate | lowercase }}
            </h3>
        </div>

        <form (submit)="createQuotation()" *ngIf="!autorating" [formGroup]="form">
            <div [ngClass]="{'d-flex justify-content-center': !headerCompleted}" class="row">
                <div [ngClass]="{'col-12 col-lg-6': !headerCompleted, 'col-12': headerCompleted}">
                    <div class="card shadow-sm mb-4">
                        <div class="card-header bg-white px-4 pt-4 border-0">
                            <h5 class="m-0 font-weight-bold">{{ 'hub.quotations.create.fieldsets.request' | translate }}</h5>
                        </div>
                        <div class="card-body border-0 px-4">
                            <div formGroupName="header">
                                <div class="row">
                                    <div [ngClass]="headerCompleted ? 'col-6' : 'col-12'" class="form-group">
                                        <label>{{ 'hub.quotations.create.fields.customer' | translate }}</label>
                                        <ng-select
                                            [clearable]="false"
                                            [items]="customers$ | async"
                                            [loading]="customersLoading"
                                            [readonly]="headerCompleted"
                                            [typeahead]="customerInput$"
                                            bindValue="id"
                                            formControlName="owner_id"
                                            placeholder="{{ 'common.type-to-search' | translate }}">
                                            <ng-template let-item="item" ng-label-tmp>
                                                <div>{{ item.name }} <span class="text-muted">|
                                                    {{ item.email }}</span>
                                                </div>
                                            </ng-template>

                                            <ng-template let-item="item" ng-option-tmp>
                                                <div>{{ item.name }} <span class="text-muted">|
                                                    {{ item.email }}</span>
                                                </div>
                                                <div>
                                                    {{ item.team_name }}
                                                </div>
                                            </ng-template>

                                            <ng-template ng-notfound-tmp>
                                                <div class="ng-option disabled">
                                                    {{ 'common.no_items_found_short' | translate : { value: 'common.item_s' | translate } }}
                                                </div>
                                            </ng-template>

                                            <ng-template ng-loadingtext-tmp>
                                                <div class="ng-option disabled">
                                                    {{ 'common.loading' | translate }}...
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>

                                <div [ngClass]="{'mb-3': headerCompleted}" class="row">
                                    <div [ngClass]="headerCompleted ? 'col-3' : 'col-6'" class="form-group">
                                        <label for="load_type">{{ 'hub.quotations.create.fields.load-type' | translate }}</label>
                                        <ng-select
                                            (change)="onLoadTypeChanged($event)"
                                            [clearable]="false"
                                            [items]="loadTypes"
                                            [readonly]="headerCompleted"
                                            bindValue="value"
                                            formControlName="load_type"
                                            id="load_type"
                                            placeholder="{{ 'common.select' | translate }}...">
                                            <ng-template let-item="item" ng-label-tmp>
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>

                                            <ng-template let-item="item" ng-option-tmp>
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>

                                    <div *ngIf="form.get('header.load_type').valid"
                                         [ngClass]="headerCompleted ? 'col-3' : 'col-6'"
                                         class="form-group">
                                        <label for="transport_mode">{{ 'hub.quotations.create.fields.modality' | translate }}</label>
                                        <ng-select
                                            [clearable]="false"
                                            [items]="transportModes[form.get('header.load_type').value]"
                                            [readonly]="headerCompleted"
                                            bindValue="value"
                                            formControlName="transport_mode"
                                            id="transport_mode"
                                            placeholder="{{ 'common.select' | translate }}...">
                                            <ng-template let-item="item" ng-label-tmp>
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>

                                            <ng-template let-item="item" ng-option-tmp>
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>

                                <div [ngClass]="{'mb-3': headerCompleted}" class="row">
                                    <div [ngClass]="headerCompleted ? 'col-6' : 'col-12'">
                                        <div class="form-group">
                                            <label for="origin">{{ 'hub.quotations.create.fields.origin' | translate }}</label>
                                            <qwyk-location-typeahead
                                                (inputChanged)="onLocationsAutocomplete($event)"
                                                [disabled]="headerCompleted"
                                                [searching]="isSearchingLocations"
                                                [suggestions]="locationsSuggestions"
                                                formControlName="origin"
                                                id="origin"
                                                labelProperty="display_name"
                                                placeholder="{{ 'hub.quotations.create.fields.origin' | translate }}">
                                                <ng-template let-item="item" qwykLocationTypeaheadItem>
                                                    <div>
                                                        <span>{{ item.display_name }}</span>
                                                        (<span>{{ item.locode }}</span>)
                                                    </div>
                                                    <small>
                                                        <span>{{ item.country_name }}</span>
                                                    </small>
                                                </ng-template>

                                                <ng-template let-item="item" qwykLocationTypeaheadPlaceholder>
                                                    <div>
                                                        <span>{{ item.display_name }}</span>
                                                        ({{item.locode}})
                                                    </div>
                                                    <small>{{ item.country_name }}</small>
                                                </ng-template>

                                                <ng-template qwykLocationTypeaheadHint>
                                                    <div>{{ 'common.location-typeahead-hint.line1' | translate }}</div>
                                                    <small>{{ 'common.location-typeahead-hint.line2' | translate }}</small>
                                                </ng-template>

                                                <ng-template qwykLocationTypeaheadNotFound>
                                                    <div>{{ 'common.location-typeahead-not-found.line1' | translate }}</div>
                                                    <small>{{ 'common.location-typeahead-not-found.line2' | translate }}</small>
                                                </ng-template>
                                            </qwyk-location-typeahead>
                                        </div>

                                        <ng-container *ngIf="form.get('header.origin').valid">
                                            <div @fadeIn class="form-group">
                                                <label>{{ 'hub.quotations.create.fields.pickup' | translate }}</label>
                                                <div
                                                    [ngClass]="{'is-invalid': form.get('header.origin_address').invalid}"
                                                    class="input-group">
                                                    <ng-select
                                                        [clearable]="false"
                                                        [items]="[
                                                            {value: 'place', label:'common.yes'},
                                                            {value: 'port', label:'common.no'}
                                                        ]"
                                                        bindLabel="label" bindValue="value"
                                                        formControlName="origin_type"
                                                        style="width: 70px;">
                                                        <ng-template let-item="item" ng-label-tmp>
                                                            <span>{{ item.label | translate }}</span>
                                                        </ng-template>

                                                        <ng-template let-item="item" ng-option-tmp>
                                                            <span>{{ item.label | translate }}</span>
                                                        </ng-template>
                                                    </ng-select>
                                                    <input
                                                        (placeSelected)="onPlaceAutocomplete($event, 'header.origin_address')"
                                                        *ngIf="form.get('header.origin_type').value === 'place'"
                                                        [ngClass]="{'is-invalid': form.get('header.origin_address').invalid}"
                                                        [placeTypes]="[]"
                                                        class="form-control ml-1"
                                                        placeholder="{{ 'hub.quotations.create.fields.pickup-address-placeholder' | translate }}"
                                                        qwykGooglePlacesAutocomplete/>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div [ngClass]="headerCompleted ? 'col-6' : 'col-12'">
                                        <div class="form-group">
                                            <label for="destination">{{ 'hub.quotations.create.fields.destination' | translate }}</label>
                                            <qwyk-location-typeahead
                                                (inputChanged)="onLocationsAutocomplete($event)"
                                                [disabled]="headerCompleted"
                                                [searching]="isSearchingLocations"
                                                [suggestions]="locationsSuggestions"
                                                formControlName="destination"
                                                id="destination"
                                                labelProperty="display_name"
                                                placeholder="{{ 'hub.quotations.create.fields.destination' | translate }}">
                                                <ng-template let-item="item" qwykLocationTypeaheadItem>
                                                    <div>
                                                        <span>{{ item.display_name }}</span>
                                                        (<span>{{ item.locode }}</span>)
                                                    </div>
                                                    <small>
                                                        <span>{{ item.country_name }}</span>
                                                    </small>
                                                </ng-template>

                                                <ng-template let-item="item" qwykLocationTypeaheadPlaceholder>
                                                    <div>
                                                        <span>{{ item.display_name }}</span>
                                                        ({{item.locode}})
                                                    </div>
                                                    <small>{{ item.country_name }}</small>
                                                </ng-template>

                                                <ng-template qwykLocationTypeaheadHint>
                                                    <div>{{ 'common.location-typeahead-hint.line1' | translate }}</div>
                                                    <small>{{ 'common.location-typeahead-hint.line2' | translate }}</small>
                                                </ng-template>

                                                <ng-template qwykLocationTypeaheadNotFound>
                                                    <div>{{ 'common.location-typeahead-not-found.line1' | translate }}</div>
                                                    <small>{{ 'common.location-typeahead-not-found.line2' | translate }}</small>
                                                </ng-template>

                                            </qwyk-location-typeahead>
                                        </div>

                                        <ng-container>
                                            <div *ngIf="form.get('header.destination').valid" @fadeIn
                                                 class="form-group">
                                                <label>{{ 'hub.quotations.create.fields.delivery' | translate }}</label>
                                                <div
                                                    [ngClass]="{'is-invalid': form.get('header.destination_address').invalid}"
                                                    class="input-group">
                                                    <ng-select
                                                        [clearable]="false"
                                                        [items]="[
                                                            { value: 'place', label:'common.yes' },
                                                            { value: 'port', label:'common.no' }
                                                        ]"
                                                        bindLabel="label"
                                                        bindValue="value"
                                                        formControlName="destination_type"
                                                        style="width: 70px;">
                                                        <ng-template let-item="item" ng-label-tmp>
                                                            <span>{{ item.label | translate }}</span>
                                                        </ng-template>

                                                        <ng-template let-item="item" ng-option-tmp>
                                                            <span>{{ item.label | translate }}</span>
                                                        </ng-template>
                                                    </ng-select>
                                                    <input
                                                        (placeSelected)="onPlaceAutocomplete($event, 'header.destination_address')"
                                                        *ngIf="form.get('header.destination_type').value === 'place'"
                                                        [ngClass]="{'is-invalid': form.get('header.destination_address').invalid}"
                                                        [placeTypes]="['address']"
                                                        class="form-control ml-1"
                                                        placeholder="{{ 'hub.quotations.create.fields.pickup-address-placeholder' | translate }}"
                                                        qwykGooglePlacesAutocomplete/>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="form.get('header').valid && headerCompleted">
                                <div class="row">
                                    <div class="form-group col-6">
                                        <label for="commodity">{{ 'hub.quotations.create.fields.commodity' | translate }}<span
                                            [ngClass]="{'text-danger': form.get('commodity').invalid, 'text-muted': form.get('commodity').valid}">*</span></label>
                                        <ng-select
                                            [clearable]="false"
                                            [items]="commodities$ | async"
                                            [ngClass]="{'is-invalid': form.get('commodity').invalid && form.get('commodity').touched}"
                                            bindLabel="description"
                                            formControlName="commodity"
                                            id="commodity"
                                            placeholder="{{ 'hub.quotations.create.fields.commodity-placeholder' | translate }}">
                                        </ng-select>
                                        <div class="invalid-feedback">
                                            {{ 'common.select-a' | translate }} {{ 'hub.quotations.create.fields.commodity' | translate | lowercase }}
                                        </div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label for="cargo_ready">{{ 'hub.quotations.create.fields.cargo-ready' | translate }}<span
                                            [ngClass]="{'text-danger': form.get('cargo_ready').invalid, 'text-muted': form.get('cargo_ready').valid}">*</span></label>
                                        <div
                                            [ngClass]="{'is-invalid': form.get('cargo_ready').invalid && form.get('cargo_ready').touched}"
                                            class="input-group">
                                            <p-calendar
                                                #cargoReadyDate
                                                class="d-flex"
                                                id="cargo_ready"
                                                formControlName="cargo_ready"
                                                [placeholder]="cargoreadyPlaceholder"
                                                [showButtonBar]="true"
                                                appendTo="body"
                                                name="cargo_ready"
                                                [inputStyle]="{ 'border-top-right-radius': 0, 'border-bottom-right-radius': 0 }"
                                                [style.flex]="1"
                                                [ngClass]="{'is-invalid': form.get('cargo_ready').invalid && form.get('cargo_ready').touched}"
                                                [minDate]="minCargoReadyDate"
                                            ></p-calendar>
                                            <div class="input-group-append">
                                                <button (click)="cargoReadyDate.toggle()"
                                                        class="btn btn-secondary calendar" type="button">
                                                    <fa-icon [icon]="['far', 'calendar']"></fa-icon>
                                                </button>
                                            </div>

                                        </div>
                                        <div class="invalid-feedback">{{ 'hub.quotations.create.fields.cargo-ready-placeholder' | translate }}</div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label for="currency">{{ 'hub.quotations.create.fields.currency' | translate }}<span
                                            [ngClass]="{'text-danger': form.get('currency').invalid, 'text-muted': form.get('currency').valid}">*</span></label>
                                        <ng-select
                                            [clearable]="false"
                                            [items]="currencies$ | async"
                                            [ngClass]="{'is-invalid': form.get('currency').invalid && form.get('currency').touched}"
                                            [searchFn]="currencySearchFn"
                                            bindLabel="name"
                                            bindValue="code"
                                            formControlName="currency"
                                            id="currency"
                                            placeholder="{{ 'hub.quotations.create.fields.currency' | translate }}">
                                            <ng-template let-item="item" ng-label-tmp>
                                                {{ item.code }} - {{ item.name}}
                                            </ng-template>
                                            <ng-template let-item="item" ng-option-tmp>
                                                {{ item.code }} - {{ item.name }}
                                            </ng-template>
                                        </ng-select>
                                        <div class="invalid-feedback">
                                            {{ 'common.select-a' | translate }} {{ 'hub.quotations.create.fields.currency' | translate }}
                                        </div>
                                    </div>

                                    <div class="form-group col-6">
                                        <label for="owner_reference">{{ 'hub.quotations.create.fields.customer-reference' | translate }}</label>
                                        <input
                                            class="form-control"
                                            formControlName="owner_reference"
                                            id="owner_reference"
                                            maxlength="255"
                                            name="owner_reference"
                                            placeholder="{{ 'hub.quotations.create.fields.customer-reference-placeholder' | translate }}"
                                            type="text"/>
                                    </div>

                                    <div class="form-group col-6">
                                        <label for="inco_term">{{ 'hub.quotations.create.fields.inco-term' | translate }}</label>
                                        <ng-select
                                            [items]="incoTerms"
                                            bindLabel="long_form"
                                            bindValue="code"
                                            formControlName="inco_term"
                                            id="inco_term"
                                            placeholder="{{ 'common.select-an' | translate }} {{ 'hub.quotations.create.fields.inco-term' | translate }}">
                                        </ng-select>
                                    </div>
                                </div>
                            </ng-container>

                            <div [ngClass]="{'mt-3': headerCompleted}" formGroupName="header">
                                <ng-container
                                    *ngIf="networkedOrganizations$ | async; let networkedOrganizations">
                                    <div *ngIf="networkedOrganizations && networkedOrganizations.length > 0"
                                         @fadeIn
                                         class="form-group">
                                        <label>Network quotation
                                            <qwyk-info
                                                text="Source rates from networked organizations by toggling the switch and selecting a partner">
                                            </qwyk-info>
                                        </label>
                                        <div class="input-group d-flex align-items-center">
                                            <div class="custom-control custom-switch custom-control-sm">
                                                <input (change)="onIsNetworkQuotationChanged($event)"
                                                       [attr.disabled]="headerCompleted ? true : null"
                                                       class="custom-control-input"
                                                       formControlName="is_network_quotation"
                                                       id="is_network_quotation"
                                                       type="checkbox">
                                                <label class="custom-control-label pl-2"
                                                       for="is_network_quotation">
                                                    {{ form.get('header.is_network_quotation').value ? '' : 'Get rates from network'}}
                                                </label>
                                            </div>
                                            <div *ngIf="form.get('header.is_network_quotation').value"
                                                 @fadeIn class="flex-grow-1">
                                                <ng-select [items]="networkedOrganizations" [multiple]="false"
                                                           [readonly]="headerCompleted"
                                                           bindValue="id"
                                                           class="w-100"
                                                           formControlName="organization_network_authorizations_id"
                                                           placeholder="Select a network partner"
                                                           style="width: 100%;">
                                                    <ng-template let-item="item" ng-label-tmp>
                                                        <div>{{ item.name }}
                                                            <span class="text-muted">
                                                                        |
                                                                {{ item.country.name }}
                                                                    </span>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template let-item="item" ng-option-tmp>
                                                        <div>{{ item.name }}
                                                            <span class="text-muted">
                                                                        |
                                                                {{ item.country.name }}
                                                                    </span>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="form.get('header.is_network_quotation').value">
                                        <div @fadeIn class="form-group">
                                            <div class="custom-control custom-switch custom-control-sm">
                                                <input [attr.disabled]="headerCompleted ? true : null"
                                                       class="custom-control-input"
                                                       formControlName="include_own_rates"
                                                       id="include_own_rates"
                                                       type="checkbox">
                                                <label class="custom-control-label pl-2"
                                                       for="include_own_rates">
                                                    Include own rates
                                                </label>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="!headerCompleted" class="card-footer bg-white border-0 px-4 pt-4">
                            <div class="form-group text-right mb-0">
                                <button [routerLink]="['/quotations', 'list']" class="btn btn-light mr-2"
                                        type="button">{{ 'common.cancel' | translate }}
                                </button>
                                <button (click)="completeHeader()" [disabled]="form.get('header').invalid"
                                        class="btn btn-primary"
                                        type="button">
                                        {{ 'common.continue' | translate }}
                                    <fa-icon [icon]="['far', 'arrow-right']" class="ml-2"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="headerCompleted" class="col-12">
                    <div class="card shadow-sm mb-4">
                        <div class="card-header bg-white py-3 px-4 pt-4 border-0">
                            <h5 class="m-0 font-weight-bold">{{ 'hub.quotations.create.fieldsets.cargo' | translate }}</h5>
                        </div>

                        <div class="card-body border-0  border-0 px-4">
                            <div *ngFor="let cargoRow of getCargoFormArray.controls; let idx = index;"
                                 formArrayName="packages">
                                <ng-container [formGroupName]="idx">
                                    <ng-container *ngIf="form.get('header.load_type').value === 'fcl'">
                                        <div class="row">
                                            <div class="form-group col-4 col-sm-3 col-md-2">
                                                <label>{{ 'hub.quotations.create.fields.quantity' | translate }}</label>
                                                <input
                                                    [ngClass]="{'is-invalid': cargoRow.get('quantity').invalid && cargoRow.get('quantity').touched}"
                                                    class="form-control"
                                                    formControlName="quantity"
                                                    placeholder="{{ 'hub.quotations.create.fields.quantity' | translate }}"
                                                    type="number"/>
                                            </div>

                                            <div
                                                class="form-group col-sm-4 col-md"
                                                [ngClass]="isOceanTransport() ? 'col-4' : 'col-8'"
                                            >
                                                <label>{{ 'hub.quotations.create.fields.container-size' | translate }}</label>
                                                <ng-select
                                                    [clearable]="false"
                                                    [items]="packagings$ | async"
                                                    [ngClass]="{'is-invalid': cargoRow.get('packaging').invalid && cargoRow.get('packaging').touched}"
                                                    bindLabel="description"
                                                    formControlName="packaging"
                                                    placeholder="{{ 'hub.quotations.create.fields.container-size' | translate }}">
                                                </ng-select>
                                            </div>

                                            <div class="form-group col-4 col-sm-4 col-md" *ngIf="this.isOceanTransport()">
                                                <label>{{ 'hub.quotations.create.fields.container-type' | translate }}</label>
                                                <ng-select
                                                    [clearable]="false"
                                                    [items]="transportModes$ | async"
                                                    bindLabel='name'
                                                    bindValue='code'
                                                    formControlName="container_type"
                                                    placeholder="{{ 'hub.quotations.create.fields.container-type' | translate }}"
                                                >
                                                </ng-select>
                                            </div>

                                            <div class="form-group col-12 col-sm-5 col-md">
                                                <label>{{ 'hub.quotations.create.fields.unit-weight' | translate }} ({{ 'common.optional' | translate | lowercase }})</label>
                                                <div class="input-group">
                                                    <input
                                                        [ngClass]="{'is-invalid': cargoRow.get('unit_weight').invalid && cargoRow.get('unit_weight').touched}"
                                                        class="form-control"
                                                        formControlName="unit_weight"
                                                        placeholder="{{ 'common.weight' | translate }}"
                                                        type="number"/>
                                                    <div class="input-group-append">
                                                        <ng-select (change)="onUOMSet(idx, $event)"
                                                                   [clearable]="false"
                                                                   [items]="[{key: 'metric', label: 'Kgs'}, {key: 'imperial', label: 'Lbs'}]"
                                                                   [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                                   [searchable]="false"
                                                                   bindLabel="label"
                                                                   bindValue="key"
                                                                   formControlName="uom"
                                                                   placeholder="{{ 'common.unit' | translate }}"
                                                                   style="min-width: 90px;">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="form.get('header.load_type').value === 'lcl'">
                                        <div class="row">
                                            <div class="form-group col-4 col-sm-4 col-md-2">
                                                <label>{{ 'hub.quotations.create.fields.quantity' | translate }}</label>
                                                <input
                                                    [ngClass]="{'is-invalid': cargoRow.get('quantity').invalid && cargoRow.get('quantity').touched}"
                                                    class="form-control"
                                                    formControlName="quantity"
                                                    min="1"
                                                    placeholder="{{ 'hub.quotations.create.fields.quantity' | translate }}"
                                                    type="number"/>
                                            </div>

                                            <div class="form-group col">
                                                <label>{{ 'hub.quotations.create.fields.packaging' | translate }}</label>
                                                <ng-select
                                                    [clearable]="false"
                                                    [items]="packagings$ | async"
                                                    [ngClass]="{'is-invalid': cargoRow.get('packaging').invalid && cargoRow.get('packaging').touched}"
                                                    bindLabel="description"
                                                    formControlName="packaging"
                                                    placeholder="{{ 'hub.quotations.create.fields.packaging' | translate }}">
                                                </ng-select>
                                            </div>

                                            <div *ngIf="getCargoFormArray.controls.length > 1" class="col-1"
                                                 style="min-width: 70px;">
                                                <button (click)="removeCargoRow(idx)"
                                                        [disabled]="form.disabled"
                                                        class="btn btn-outline-danger btn-sm border-0"
                                                        style="margin-top: 32px;" type="button">
                                                    <fa-icon [icon]="['far', 'trash']"></fa-icon>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="form-group col-12 col-sm-10 col-md-6">
                                                <label>{{ 'hub.quotations.create.fields.unit-weight' | translate }}</label>
                                                <div class="input-group">
                                                    <input
                                                        [ngClass]="{'is-invalid': cargoRow.get('unit_weight').invalid && cargoRow.get('unit_weight').touched}"
                                                        class="form-control"
                                                        formControlName="unit_weight" min="0.1"
                                                        placeholder="{{ 'common.weight' | translate }}"
                                                        type="number"/>
                                                    <div class="input-group-append">
                                                        <ng-select (change)="onUOMSet(idx, $event)"
                                                                   [clearable]="false"
                                                                   [items]="[{key: 'metric', label: 'Kgs'}, {key: 'imperial', label: 'Lbs'}]"
                                                                   [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                                   [searchable]="false"
                                                                   bindLabel="label"
                                                                   bindValue="key"
                                                                   formControlName="uom"
                                                                   style="min-width: 90px;">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-12 col-sm-10 col-md-6">
                                                <label>{{ 'common.unit' | translate }}
                                                    {{ (!dimensionsInput ? 'common.volume' : 'common.dimensions') | translate | lowercase }}</label>
                                                <div *ngIf="dimensionsInput" class="input-group">
                                                    <input
                                                        [ngClass]="{'is-invalid': cargoRow.get('unit_length').invalid && cargoRow.get('unit_length').touched}"
                                                        class="form-control"
                                                        formControlName="unit_length"
                                                        placeholder="{{ 'common.length' | translate }}"
                                                        type="number"
                                                    />
                                                    <input
                                                        [ngClass]="{'is-invalid': cargoRow.get('unit_width').invalid && cargoRow.get('unit_width').touched}"
                                                        class="form-control"
                                                        formControlName="unit_width"
                                                        placeholder="{{ 'common.width' | translate }}"
                                                        type="number"
                                                    />
                                                    <input
                                                        [ngClass]="{'is-invalid': cargoRow.get('unit_height').invalid && cargoRow.get('unit_height').touched}"
                                                        class="form-control"
                                                        formControlName="unit_height"
                                                        placeholder="{{ 'common.height' | translate }}"
                                                        type="number"
                                                    />
                                                    <div class="input-group-append">
                                                        <ng-select (change)="onUOMSet(idx, $event)"
                                                                   [clearable]="false"
                                                                   [items]="[{key: 'metric', label: 'Cm'}, {key: 'imperial', label: 'In'}]"
                                                                   [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                                   [searchable]="false"
                                                                   bindLabel="label"
                                                                   bindValue="key"
                                                                   formControlName="uom"
                                                                   style="min-width: 90px;">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div *ngIf="!dimensionsInput" class="input-group">
                                                    <input (change)="onVolumeChange(idx, $event)"
                                                           [ngClass]="{'is-invalid': cargoRow.get('unit_volume').invalid && cargoRow.get('unit_volume').touched}"
                                                           class="form-control" formControlName="unit_volume"
                                                           placeholder="{{ 'common.volume' | translate }}"
                                                           type="number"
                                                    />
                                                    <div class="input-group-append">
                                                        <ng-select (change)="onUOMSet(idx, $event)"
                                                                   [clearable]="false"
                                                                   [items]="[{key: 'metric', label: 'Cbm'}, {key: 'imperial', label: 'Cft'}]"
                                                                   [ngClass]="{'is-invalid': cargoRow.get('uom').invalid && cargoRow.get('uom').touched}"
                                                                   [searchable]="false"
                                                                   bindLabel="label"
                                                                   bindValue="key"
                                                                   formControlName="uom"
                                                                   style="min-width: 90px;">
                                                        </ng-select>
                                                    </div>

                                                </div>
                                                <div *ngIf="cargoRow.get('unit_volume').errors?.max">
                                                    <small class="text-danger">
                                                        The maximum allowed value of volume is {{ cargoRow.get('unit_volume').errors.max.max }}
                                                    </small>
                                                </div>
                                                <div *ngIf="cargoRow.get('unit_height').errors?.max">
                                                    <small class="text-danger">
                                                        The maximum allowed value of height is {{ cargoRow.get('unit_height').errors.max.max }}
                                                    </small>
                                                </div>
                                                <div *ngIf="cargoRow.get('unit_length').errors?.max">
                                                    <small class="text-danger">
                                                        The maximum allowed value of length is {{ cargoRow.get('unit_length').errors.max.max }}
                                                    </small>
                                                </div>
                                                <div *ngIf="cargoRow.get('unit_width').errors?.max">
                                                    <small class="text-danger">
                                                        The maximum allowed value of width is {{ cargoRow.get('unit_width').errors.max.max }}
                                                    </small>
                                                </div>
                                                <small *ngIf="idx === 0" class="form-text" id="wantsQuoteHelp">
                                                    <a (click)="dimensionsInput = !dimensionsInput"
                                                       href="javascript:void(0);">
                                                        {{ 'common.enter' | translate }} {{ (dimensionsInput ? 'common.volume' : 'common.dimensions') | translate | lowercase }}
                                                    </a>
                                                </small>

                                            </div>

                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="form.get('header.load_type').value === 'lcl'" class="card-footer bg-white">
                            <button (click)="addCargoRow()" [disabled]="getCargoFormArray.invalid"
                                    class="btn btn-outline-secondary btn-sm border-0">
                                <fa-icon [icon]="['far', 'plus']" class="mr-2"></fa-icon>
                                {{ 'common.add-item' | translate : { value: 'hub.quotations.create.fieldsets.cargo' | translate | lowercase } }}
                            </button>
                        </div>
                    </div>

                    <div class="card shadow-sm">
                        <div class="card-header bg-white py-3 px-4 pt-4 border-0">
                            <h5 class="m-0 font-weight-bold">{{ 'hub.quotations.create.fieldsets.additionals' | translate }}</h5>
                        </div>
                        <div class="card-body border-0 px-4">
                            <div class="row">
                                <ng-container formGroupName="header">
                                    <div *ngIf="form.get('header.origin_type').value === 'port'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="require_origin_charges"
                                                   id="require_origin_charges"
                                                   name="require_origin_charges" type="checkbox">
                                            <label class="custom-control-label"
                                                   for="require_origin_charges">
                                                {{ 'hub.quotations.additionals.require-origin-charges' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                            class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="require_freight_charges"
                                                   id="require_freight_charges"
                                                   name="require_freight_charges" type="checkbox">
                                            <label class="custom-control-label"
                                                   for="require_freight_charges">
                                                {{ 'hub.quotations.additionals.require-freight-charges' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="form.get('header.destination_type').value === 'port'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="require_destination_charges"
                                                   id="require_destination_charges"
                                                   name="require_destination_charges" type="checkbox">
                                            <label class="custom-control-label"
                                                   for="require_destination_charges">
                                                {{ 'hub.quotations.additionals.require-destination-charges' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container formGroupName="additionals">
                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="customs_clearance_origin"
                                                   id="customs_clearance_origin"
                                                   name="customs_clearance_origin" type="checkbox">
                                            <label class="custom-control-label"
                                                   for="customs_clearance_origin">
                                                {{ 'hub.quotations.additionals.origin-customs-clearance' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="customs_clearance_destination"
                                                   id="customs_clearance_destination"
                                                   name="customs_clearance_destination"
                                                   type="checkbox">
                                            <label class="custom-control-label"
                                                   for="customs_clearance_destination">
                                                {{ 'hub.quotations.additionals.destination-customs-clearance' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="cargo_insurance"
                                                   id="cargo_insurance" name="cargo_insurance"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="cargo_insurance">
                                                {{ 'hub.quotations.additionals.cargo-insurance' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="hazardous_cargo"
                                                   id="hazardous_cargo" name="hazardous_cargo"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="hazardous_cargo">
                                                {{ 'hub.quotations.additionals.hazardous-cargo' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="refrigerated"
                                                   id="refrigerated" name="refrigerated"
                                                   type="checkbox">
                                            <label class="custom-control-label"
                                                   for="refrigerated">
                                                {{ 'hub.quotations.additionals.refrigerated' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="overweight"
                                                   id="overweight" name="overweight"
                                                   type="checkbox">
                                            <label class="custom-control-label"
                                                   for="overweight">
                                                {{ 'hub.quotations.additionals.overweight' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.load_type').value === 'lcl'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="stackable"
                                                   id="stackable" name="stackable" type="checkbox">
                                            <label class="custom-control-label" for="stackable">
                                                {{ 'hub.quotations.additionals.stackable' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.origin_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="residential_pickup"
                                                   id="residential_pickup" name="residential_pickup"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="residential_pickup">
                                                {{ 'hub.quotations.additionals.residential-pickup' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.origin_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="liftgate_pickup"
                                                   id="liftgate_pickup" name="liftgate_pickup"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="liftgate_pickup">
                                                {{ 'hub.quotations.additionals.liftgate-pickup' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.origin_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="limited_access_pickup"
                                                   id="limited_access_pickup"
                                                   name="limited_access_pickup"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="limited_access_pickup">
                                                {{ 'hub.quotations.additionals.limited-access-pickup' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.destination_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="residential_delivery"
                                                   id="residential_delivery" name="residential_delivery"
                                                   type="checkbox">
                                            <label class="custom-control-label"
                                                   for="residential_delivery">
                                                {{ 'hub.quotations.additionals.residential-delivery' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.destination_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="liftgate_delivery"
                                                   id="liftgate_delivery" name="liftgate_delivery"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="liftgate_delivery">{{
                                                'liftgate_delivery' | slugToTitle }}</label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.destination_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="delivery_appointment_required"
                                                   id="delivery_appointment_required"
                                                   name="delivery_appointment_required"
                                                   type="checkbox">
                                            <label class="custom-control-label"
                                                   for="delivery_appointment_required">
                                                {{ 'hub.quotations.additionals.delivery-appointment-rqrd' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="form.get('header.destination_type').value === 'place'"
                                         class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input"
                                                   formControlName="limited_access_delivery"
                                                   id="limited_access_delivery"
                                                   name="limited_access_delivery" type="checkbox">
                                            <label class="custom-control-label"
                                                   for="limited_access_delivery">
                                                {{ 'hub.quotations.additionals.limited-access-delivery' | translate }}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-xl-4">
                                        <div class="custom-control custom-switch mt-2">
                                            <input class="custom-control-input" formControlName="flatbed_required"
                                                   id="flatbed_required" name="flatbed_required"
                                                   type="checkbox">
                                            <label class="custom-control-label" for="flatbed_required">
                                                {{ 'hub.quotations.additionals.flatbed-required' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="error" class="alert alert-danger mt-3">
                {{ 'common.generic-error' | translate }}
            </div>

            <ng-container *ngIf="headerCompleted">
                <div class="d-flex align-items-center justify-content-between mt-4">
                    <div class="form-group">
                        <div class="custom-control custom-switch custom-control-lg">
                            <input [(ngModel)]="autorate" [disabled]="busy" [ngModelOptions]="{standalone: true}"
                                   class="custom-control-input"
                                   id="autorate" type="checkbox">
                            <label class="custom-control-label" for="autorate">
                                {{ 'hub.quotations.create.fields.auto-rate-quotation' | translate }}
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <button [disabled]="busy" [routerLink]="['/quotations', 'list']" class="btn btn-light mr-2"
                                type="button">{{ 'common.cancel' | translate }}
                        </button>
                        <button [disabled]="busy" class="btn btn-primary btn-lg">
                            <fa-icon [icon]="['far', 'hand-holding-usd']" class="mr-2"></fa-icon>
                            {{ 'common.create' | translate }} {{ 'common.quotations.quotation' | translate | lowercase }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </form>

        <div *ngIf="autorating" class="row justify-content-center">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="autorate-animation m-3 d-flex align-items-center  justify-content-center">
                            <fa-icon [icon]="['far', 'file-csv']" id="file1" size="3x"></fa-icon>
                            <fa-icon [icon]="['far', 'file-spreadsheet']" id="file2" size="3x"></fa-icon>
                            <fa-icon [icon]="['far', 'file-invoice']" id="file3" size="3x"></fa-icon>
                            <fa-icon [icon]="['far', 'file-contract']" id="file4" size="3x"></fa-icon>
                            <fa-icon [icon]="['far', 'cog']" id="gear" size="4x"></fa-icon>
                        </div>
                        <h5>We're autorating this quotation</h5>
                        <p class="mb-0">This will only take a moment...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
