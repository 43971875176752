/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate, MasterDataService } from '@qwyk/core';
import { SimpleModalComponent } from '@qwyk/ui-standalone';
import { map, tap } from 'rxjs/operators';
import { CustomerUsersService } from '../../services/customer-users.service';
import { Validators as ReactiveValidators } from 'angular-reactive-validation';
import { Hub } from '@qwyk/models';
import { SearchMagayaEntityModalComponent } from '../search-magaya-entity-modal/search-magaya-entity-modal.component';
import { MagayaEntity } from '../../models';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'qwyk-customer-user-detail',
    templateUrl: './customer-user-detail.component.html',
    styleUrls: ['./customer-user-detail.component.scss'],
})
export class CustomerUserDetailComponent implements CanComponentDeactivate {
    @Input() public isMagayaNetwork = false;
    @Input() public error: any;
    @Output()
    public save: EventEmitter<any> = new EventEmitter();
    @Output()
    public deactivate: EventEmitter<any> = new EventEmitter();
    @Output()
    public activate: EventEmitter<Hub.CustomerUser> = new EventEmitter();
    public form: FormGroup;
    public loadingTeams = true;
    public teams$ = this.service
        .getTeams({ pageSize: 100000 })
        .pipe(tap(() => (this.loadingTeams = false)));
    public loadingSites = true;
    public sites$ = this.service
        .getSites({ pageSize: 100000, active: true })
        .pipe(tap(() => (this.loadingSites = false)));
    public loadingCountries = false;
    public countries$ = this.masterData
        .getCountries()
        .pipe(tap(() => (this.loadingCountries = false)));
    public permissions$ = of([]);
    public permissions: any[];

    constructor(
        private fb: FormBuilder,
        private modal: NgbModal,
        private translate: TranslateService,
        private service: CustomerUsersService,
        private masterData: MasterDataService
    ) {}

    private _user: any;

    public get user(): any {
        return this._user;
    }

    @Input()
    public set user(user: any) {
        this._user = user;
        setTimeout(() => {
            this.setForm(user);
        }, 200);
        this.permissions$ = this.service.getPermissions(user).pipe(
            map(e => {
                return e.reduce((p, c) => {
                    if (!p[c.pivot.customer_team_id]) {
                        p[c.pivot.customer_team_id] = [];
                    }
                    p[c.pivot.customer_team_id].push(c.key);
                    return p;
                }, {});
            }),
            tap(e => {
                this.permissions = e;
            })
        );
    }

    private _saving: boolean;

    public get saving(): boolean {
        return this._saving;
    }

    @Input()
    public set saving(saving: boolean) {
        this._saving = saving;
        setTimeout(() => {
            if (this.form) {
                if (saving) {
                    this.form.disable();
                } else {
                    this.form.enable();
                    this.form.get('email').disable();
                    this.form.get('username').disable();
                }
            }
        }, 0);
    }

    public onSave() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        const payload = {
            id: this.user.id,
            ...this.form.value,
            permissions: this.permissions,
        };
        this.form.markAsPristine();
        this.save.emit(payload);
    }

    public onActivate() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const payload = {
            id: this.user.id,
            ...this.form.value,
        };

        this.form.markAsPristine();
        this.activate.emit(payload);
    }

    public onDeactivate() {
        const modalRef = this.modal.open(SimpleModalComponent, {
            centered: true,
        });

        const component: SimpleModalComponent = modalRef.componentInstance;
        component.header = this.translate.instant(
            'hub.administration.portal-users.deactivate-user'
        );
        component.message = this.translate.instant(
            'hub.administration.portal-users.deactivate-message'
        );
        component.dismissAction = this.translate.instant('common.no');
        component.confirmAction = this.translate.instant('common.yes');
        component.confirmStyle = 'btn-danger';

        component.showInput = true;
        component.inputValue = false;
        component.inputType = 'checkbox';
        component.inputLabel = this.translate.instant(
            'hub.administration.portal-users.privacy-compliant-deactivation'
        );
        component.inputHint = this.translate.instant(
            'hub.administration.portal-users.deactivate-hint'
        );

        modalRef.result.then(
            scrub => {
                console.log(scrub);
                this.form.markAsPristine();
                this.deactivate.emit({ user: this.user, scrub });
            },
            () => null
        );
    }

    canDeactivate() {
        if (this.form.dirty) {
            return confirm(
                this.translate.instant(
                    'portal.settings.profile.unsaved-changes'
                )
            );
        }
        return true;
    }

    public onSearchForMagayaEntity(): void {
        const modalRef = this.modal.open(SearchMagayaEntityModalComponent, {
            size: 'lg',
            centered: true,
        });

        modalRef.result.then(
            (magayaEntity: MagayaEntity) => {
                const patch = {
                    magaya_customer_guid: magayaEntity.guid,
                    magaya_customer_name: magayaEntity.name,
                };
                if (!this.form.get('company_name').value) {
                    patch['company_name'] = magayaEntity.name;
                }
                this.form.patchValue(patch);
            },
            () => {
                this.form.get('magaya_customer_guid').reset(null);
                this.form.get('magaya_customer_name').reset(null);
            }
        );
    }

    private setForm(user) {
        const group = new UntypedFormGroup({
            name: new UntypedFormControl(user.name, [
                ReactiveValidators.required(
                    this.translate.instant(
                        'hub.administration.portal-users.enter-a-name'
                    )
                ),
                ReactiveValidators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ]),
            username: new UntypedFormControl(user.username),
            email: new UntypedFormControl(user.email),
            registered_site_id: new UntypedFormControl(
                user.registered_site_id,
                [
                    ReactiveValidators.required(
                        this.translate.instant(
                            'hub.administration.portal-users.select-a-site-from-the-list'
                        )
                    ),
                ]
            ),
            restrict_to_registered_site: new UntypedFormControl(
                user.restrict_to_registered_site,
                [
                    ReactiveValidators.required(
                        this.translate.instant(
                            'hub.administration.portal-users.select-whether-to-restrict'
                        )
                    ),
                ]
            ),

            company_name: new UntypedFormControl(user.company_name, [
                ReactiveValidators.required(
                    this.translate.instant(
                        'hub.administration.portal-users.enter-a-company-name'
                    )
                ),
                ReactiveValidators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ]),
            job_title: new UntypedFormControl(user.job_title, [
                ReactiveValidators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ]),
            location: new UntypedFormControl(user.location, [
                ReactiveValidators.maxLength(191, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ]),
            country_code: new UntypedFormControl(user.country_code),
            phone_number: new UntypedFormControl(user.phone_number, [
                ReactiveValidators.maxLength(15, maxLength =>
                    this.translate.instant(
                        'common.validation.maxLength',
                        { maxLength },
                    )
                ),
            ]),
            marketing_optin: new UntypedFormControl(user.marketing_optin),
        });

        if (!this.isMagayaNetwork) {
            group.addControl(
                'team_id',
                new FormControl(user.team_id, [
                    ReactiveValidators.required(
                        this.translate.instant(
                            'hub.administration.portal-users.select-a-customer-from-the-list'
                        )
                    ),
                ])
            );
        } else {
            group.addControl(
                'magaya_customer_guid',
                new FormControl(
                    {
                        value: null,
                        disabled: false,
                    },
                    [
                        ReactiveValidators.required(
                            this.translate.instant(
                                'hub.administration.portal-users.select-an-entity-from-magaya'
                            )
                        ),
                    ]
                )
            );
            group.addControl(
                'magaya_customer_name',
                new FormControl(
                    {
                        value: null,
                        disabled: false,
                    },
                    [
                        ReactiveValidators.required(
                            this.translate.instant(
                                'hub.administration.portal-users.select-an-entity-from-magaya'
                            )
                        ),
                    ]
                )
            );
        }

        this.form = group;
    }
}
