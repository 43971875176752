import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { map, tap } from 'rxjs/operators';
import { TableLazyLoadEvent } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

import { UsersFacade } from '../../+state/users.facade';
import { UsersService } from '../../services/users.service';

@Component({
    selector: 'qwyk-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {
    public users$ = this.users.allUsers$;
    public loading$ = this.users.loading$;
    public loaded$ = this.users.loaded$;
    public pagination$ = this.users.pagination$;
    private pageSize = 25;

    rolesLoading = true;
    roles$ = this.service.getRoles().pipe(
        map(
            roles =>
                roles.map(role => {
                    return {
                        value: role.id,
                        label: this.translate.instant(
                            'hub.settings.users.roles.' + role.slug
                        ),
                    };
                }),
            tap(() => (this.rolesLoading = false))
        )
    );

    searchForm = this.fb.group({
        email: [null],
        name: [null],
        role_id: [null],
        location: [null],
    });

    constructor(
        private fb: FormBuilder,
        private users: UsersFacade,
        private service: UsersService,
        private translate: TranslateService
    ) {}

    public loadUsersLazy(event: TableLazyLoadEvent): void {
        setTimeout(() => {
            let page = 1;
            if (event.first && event.rows) {
                page = event.first / event.rows + 1;
            }
            this.search(page);
        }, 50);
    }

    public search(page = 1) {
        const searchValue = this.searchForm.value;
        Object.keys(searchValue).forEach(key => {
            if (searchValue[key] === null) {
                delete searchValue[key];
            }
        });

        const query = {
            ...searchValue,
            pageSize: this.pageSize,
            page,
        };

        this.users.loadHubUsers(query);
    }

    public resetSearch() {
        this.searchForm.reset();
        this.search();
    }
}
