<ng-container *ngIf="userState$ | async; let state;">
    <div class="container px-4 py-3">
        <div class="row justify-content-center">
            <div class="col-6 d-flex align-items-center">
                <div class="avatar-container rounded-circle border bg-light">
                    <img *ngIf="state.user" style="width: 100%; height: 100%;"
                        [src]="'https://www.gravatar.com/avatar/' + (state.user.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + (state.user.name | urlEncode) + '/64/097aff/ffffff/2//false/true/true'">
                    <!-- <div class="hover-overlay d-flex align-items-center justify-content-center">
                        <button class="btn text-light">
                            <fa-icon [icon]="['far', 'camera']" size="2x"></fa-icon>
                        </button>
                    </div> -->
                </div>

                <div class="ml-3 mt-1" *ngIf="!state.user && state.loading">
                    <div>
                        <div class="qw-skeleton" style="height: 2rem; width: 240px; margin-bottom: 0.5rem;"></div>
                    </div>
                    <div class="qw-skeleton" style="height: 1rem; width: 180px;"></div>
                </div>

                <div class="ml-3" *ngIf="state.user" [@fadeInOut]="state.user ? 'in' : null">
                    <h3>{{ state.user.name }}</h3>
                    <div>{{ state.user.email }}</div>
                </div>

                <div class="ml-3" *ngIf="state.error">
                    <p class="text-danger">An error occured. Please refresh the page and try again or contact support if
                        the
                        problem persists.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-3">
        <div class="row justify-content-center">
            <div class="col-6">
                <div class="mt-3">
                    <div class="card shadow-sm">
                        <div class="card-body pr-2">
                            <h6 class="mb-3">About</h6>
                            <div class="pt-1" *ngIf="!state.user && state.loading">
                                <div class="mt-3 mb-4">
                                    <div class="qw-skeleton" style="height: 1.5rem; width: 50%;"></div>
                                </div>
                                <div class="mt-3 mb-4">
                                    <div class="qw-skeleton" style="height: 1.5rem; width: 70%;"></div>
                                </div>
                                <div class="mt-3 mb-4">
                                    <div class="qw-skeleton" style="height: 1.5rem; width: 90%;"></div>
                                </div>
                            </div>
                            <form [formGroup]="aboutForm" class="form qw-form about-form" (submit)="submitAboutForm()"
                                *ngIf="state.user" [@fadeInOut]>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <fa-icon [icon]="['far', 'building']" [fixedWidth]="true"
                                            class="input-group-text" id="company-icon">
                                        </fa-icon>
                                    </div>
                                    <input type="text" class="form-control" formControlName="organization_name"
                                        aria-describedby="company-icon" placeholder="Your organization"
                                        (focus)="$event.target.select()" placement="right"
                                        ngbTooltip="You cannot edit your organization.">
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <fa-icon [icon]="['far', 'briefcase']" [fixedWidth]="true"
                                            class="input-group-text" id="jobtitle-icon">
                                        </fa-icon>
                                    </div>
                                    <input type="text" class="form-control" formControlName="job_title"
                                        aria-describedby="jobtitle-icon" placeholder="Your job title"
                                        (focus)="$event.target.select()" placement="right" container="body"
                                        ngbTooltip="Click to edit your job title here."
                                        [ngClass]="{'is-invalid': validationErrors?.job_title || (aboutForm.get('job_title').touched && aboutForm.get('job_title').invalid)}">
                                    <div class="invalid-feedback text-center">
                                        <div *ngIf="aboutForm.get('job_title').invalid">Please enter your job title.
                                        </div>
                                        <div *ngFor="let err of validationErrors?.job_title">{{ err }}</div>
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <fa-icon [icon]="['far', 'map-pin']" [fixedWidth]="true"
                                            class="input-group-text" id="location-icon">
                                        </fa-icon>
                                    </div>
                                    <input type="text" class="form-control" formControlName="location"
                                        aria-describedby="location-icon" placeholder="Your location"
                                        (focus)="$event.target.select()" placement="right" container="body"
                                        ngbTooltip="Click to edit your location here."
                                        [ngClass]="{'is-invalid': validationErrors?.location || (aboutForm.get('location').touched && aboutForm.get('location').invalid)}">
                                    <div class="invalid-feedback text-center">
                                        <div *ngIf="aboutForm.get('location').invalid">Please enter your location.</div>
                                        <div *ngFor="let err of validationErrors?.location">{{ err }}</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary" role="submit" [disabled]="aboutForm.disabled">
                                        <ng-container *ngIf="!formsUpdating; else formBusy;">
                                            Save changes
                                        </ng-container>
                                    </button>
                                </div>
                            </form>
                            <div *ngIf="state.error">
                                <p class="text-danger">An error occured. Please refresh the page and try again or
                                    contact support if the problem persists.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card shadow-sm mt-3">
                        <div class="card-body pr-2">
                            <h6 class="mb-3">Settings</h6>
                            <div class="pt-1" *ngIf="!state.user && state.loading">
                                <div class="mt-3 mb-4">
                                    <div class="qw-skeleton" style="height: 1.5rem; width: 50%;"></div>
                                </div>
                                <div class="mt-3 mb-4">
                                    <div class="qw-skeleton" style="height: 1.5rem; width: 90%;"></div>
                                </div>
                                <div class="mt-3 mb-4">
                                    <div class="qw-skeleton" style="height: 1.5rem; width: 50%;"></div>
                                </div>
                            </div>

                            <form [formGroup]="settingsForm" class="form qw-form about-form"
                                (submit)="submitSettingsForm()" *ngIf="state.user" [@fadeInOut]>
                                <div class="input-group mb-3"
                                    *ngIf="state.user.organization_features?.language_picker?.value">
                                    <div class="input-group-prepend">
                                        <fa-icon [icon]="['far', 'language']" [fixedWidth]="true"
                                            class="input-group-text" id="company-icon2">
                                        </fa-icon>
                                    </div>
                                    <select
                                        id="language"
                                        name="language"
                                        class="form-control"
                                        placement="right"
                                        container="body"
                                        formControlName="language"
                                        ngbTooltip="Click to edit your language here."
                                        [ngClass]="{'is-invalid': validationErrors?.language || (settingsForm.get('language').touched && settingsForm.get('language').invalid)}">
                                        <option *ngFor="let l of availableLanguages" [value]="l.key">
                                            <span class="text-muted">{{ l.name }}</span>
                                        </option>
                                    </select>
                                </div>

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <fa-icon [icon]="['far', 'clock']" [fixedWidth]="true" class="input-group-text"
                                            id="timezone-icon">
                                        </fa-icon>
                                    </div>

                                    <select id="timezone" name="timezone" class="form-control" placement="right"
                                        container="body" ngbTooltip="Click to edit your Timezone here."
                                        formControlName="timezone" aria-describedby="timezone-icon"
                                        [ngClass]="{'is-invalid': validationErrors?.timezone || (settingsForm.get('timezone').touched && settingsForm.get('timezone').invalid)}">
                                        <option [value]="null" disabled selected>Select your timezone</option>
                                        <option *ngFor="let t of state.timezones" [value]="t.identifier">
                                            {{ t.identifier }} <span class="text-muted">{{ t.offset }}</span></option>
                                    </select>
                                    <div class="invalid-feedback text-center">
                                        <div *ngIf="settingsForm.get('timezone').invalid">Please select a valid
                                            timezone.</div>
                                        <div *ngFor="let err of validationErrors?.timezone">{{ err }}</div>
                                    </div>
                                </div>

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <fa-icon [icon]="['far', 'ruler']" [fixedWidth]="true" class="input-group-text"
                                            id="company-icon3">
                                        </fa-icon>
                                    </div>
                                    <select class="form-control" placement="right" container="body"
                                        [ngClass]="{'is-invalid': validationErrors?.measurements || (settingsForm.get('measurements').touched && settingsForm.get('measurements').invalid)}"
                                        ngbTooltip="Click to edit your measurements here."
                                        formControlName="measurements">
                                        <option [value]="null" disabled selected>Select your units</option>
                                        <option value="metric">Metric</option>
                                        <option value="imperial">Imperial</option>
                                    </select>
                                    <div class="invalid-feedback text-center">
                                        <div *ngIf="settingsForm.get('measurements').invalid">Please select a valid
                                            unit.</div>
                                        <div *ngFor="let err of validationErrors?.measurements">{{ err }}</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-primary" role="submit" [disabled]="settingsForm.disabled">
                                        <ng-container *ngIf="!formsUpdating; else formBusy;">
                                            Save changes
                                        </ng-container>
                                    </button>
                                </div>
                            </form>
                            <div *ngIf="state.error">
                                <p class="text-danger">An error occured. Please refresh the page and try again or
                                    contact support if the problem persists.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #formBusy>
    <fa-icon [icon]="['far', 'spinner']" animation="spin-pulse" class="mr-2"></fa-icon>Saving
</ng-template>
