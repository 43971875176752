// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = {
    name: 'develop',
    badge: 'badge-warning',
    production: false,
    backendServer: 'https://hub-server.test',
    schedulesServer: 'http://schedules2.test',
    microservicesServer: 'https://microservices.api.next.qwykportals.com', // 'https://api.next.qwykportals.com:3001',
    hostnameMask: '^([A-Za-z0-9\\.\\-\\_]*).localhost$',
    inboundEmailsDomain: 'incoming.qwykportals.com',
    auth: {
        bearerTokenConfig: {
            allowedDomains: ['hub2.test', 'hub-server.test', 'localhost:9090'],
            ignoreSubdomains: true,
            ignorePaths: ['^/api/siteconfig/', '^/api/portals/auth/login$'],
        },
    },
    echoConfig: {
        userModel: 'App.Models.Portals.Auth.User',
        notificationNamespace: 'App\\Notifications',
        options: {
            broadcaster: 'pusher',
            key: '0c41afe0ea23a0b6294c',
            cluster: 'eu',
            forceTLS: true,
            authEndpoint: 'https://hub-server.test/api/portals/broadcasting/auth',
            enabledTransports: ['ws', 'wss'],
        },
    },
    flags: {
        enableOnboarding: true,
        enableHelp: true,
        enablePurchaseOrders: true,
    },
    utils: {
        Algolia: {
            applicationId: '3G8S1ON0FI',
            adminApiKey: 'c397d91713a36b2e696eb4454f22ec40',
        },
        LogRocket: {
            enable: false,
            appId: 'wozolw/portals-client-dev',
        },
        Sentry: {
            enabled: false,
            dsn: 'https://bc9cb816ce4e4e3280ae7811b9d51b56@sentry.io/4815443',
            traceOrigins: ['http://localhost:4200', 'http://hub2.test'],
            tracesSampleRate: 1.0,
        },
        Veem: {
            environment: 'sandbox',
            clientId: 'MagayaDFP-VnqdrKYa9Y',
        },
        Amplitude: {
            enabled: false,
            apiKey: 'c56f437e325b29066c927fba8c3873f5',
        },
        Segment: {
            apiKey: 'osAGEHvLJxUD8BYvDW0Jjxskut6tYzx1',
            debug: false,
            loadOnInitialization: true,
        },
    },
    translation: {
        enabled: true,
        defaultLanguage: 'en',
        showFlags: false,
        languages: [
            { key: 'en', name: 'English', flag: 'gb', enabled: true },
            { key: 'es', name: 'Español', flag: 'es', enabled: true },
            { key: 'nl', name: 'Nederlands', flag: 'nl', enabled: true },
            { key: 'de', name: 'Deutsch', flag: 'de', enabled: true },
            { key: 'fr', name: 'Français', flag: 'fr', enabled: true },
        ],
    },
};
