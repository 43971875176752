<div class="py-3 pb-5 bg-light min-vh-100">
    <div class="container">
        <div class="small text-muted mb-3 border-bottom pb-2">
            <a [routerLink]="['/']" class="text-muted">DFP</a>
            &rsaquo;
            <a [routerLink]="['./']" class="text-muted font-weight-bold"
            >Notifications</a
            >
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <h3 class="font-weight-normal m-0">Notifications</h3>
        </div>
        <div class="my-3">
            <qwyk-notifications-list
                    [error]="error$ | async"
                    [loading]="loading$ | async"
                    [notifications]="notifications$ | async"
            ></qwyk-notifications-list>
        </div>
        <div *ngIf="pagination$ | async; let pagination;" class="text-center">
            <ng-container *ngIf="pagination.total > pagination.per_page">
                <ngb-pagination
                        (pageChange)="onPageChange($event)"
                        [collectionSize]="pagination.total"
                        [maxSize]="5"
                        [pageSize]="pagination.per_page"
                        [page]="pagination.current_page" class="d-flex justify-content-center">
                </ngb-pagination>
                <small class="text-muted">{{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }}</small>
            </ng-container>
        </div>
    </div>
</div>
