import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '@qwyk/core';
import { Observable, Subscription } from 'rxjs';
import { CustomerUsersFacade } from '../../+state/customer-users.facade';
import { Hub } from '@qwyk/models';
import { AuthenticationFacade } from '@qwyk/hub/authentication';

@Component({
    selector: 'qwyk-customer-user-view',
    templateUrl: './customer-user-view.component.html',
    styleUrls: ['./customer-user-view.component.scss'],
})
export class CustomerUserViewComponent
    implements OnDestroy, CanComponentDeactivate
{
    private route = inject(ActivatedRoute);
    private customerUsers = inject(CustomerUsersFacade);
    private authentication = inject(AuthenticationFacade);

    private actionsSubscription: Subscription;
    public selectedCustomerUser$ = this.customerUsers.selectedCustomerUsers$;
    public saving$ = this.customerUsers.loading$;
    public error$ = this.customerUsers.error$;
    public isMagayaNetwork$ = this.authentication.isMagayaNetwork$;

    @ViewChild('customerUserComponent', { static: false })
    customerUserComponent: CanComponentDeactivate;

    constructor() {
        this.actionsSubscription = this.route.params.subscribe(params => {
            this.customerUsers.selectCustomerUser(params.id);
        });
    }

    ngOnDestroy(): void {
        this.actionsSubscription.unsubscribe();
    }
    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        if (
            this.customerUserComponent &&
            this.customerUserComponent.canDeactivate
        ) {
            return this.customerUserComponent.canDeactivate();
        }
        return true;
    }

    onCreate(user: Hub.CustomerUser) {
        this.customerUsers.createCustomerUser(user);
    }

    onUpdate(user: Hub.CustomerUser) {
        this.customerUsers.updateCustomerUser(user.id, user);
    }

    onDeactivate(payload: { user: Hub.CustomerUser; scrub: boolean }) {
        this.customerUsers.deactivateCustomerUser(
            payload.user.id,
            payload.scrub
        );
    }

    onActivate(user: Hub.CustomerUser) {
        this.customerUsers.activateCustomerUser(user);
    }
}
