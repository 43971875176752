<div [ngClass]="class" class="card">
    <div
        class="card-header bg-white border-0 d-flex justify-content-between align-items-center py-3"
    >
        <h5 class="m-0">{{ 'common.filters' | translate }}</h5>
        <button (click)="formExpanded = !formExpanded" class="btn">
            <fa-icon [icon]="['far', formExpanded ? 'angle-down' : 'angle-up']">
            </fa-icon>
        </button>
    </div>

    <div class="flex-grid-1">
        <form
            (ngSubmit)="onSearch()"
            (reset)="onReset()"
            [formGroup]="form"
            class="card-body pt-0"
            [ngClass]="{ collapsed: !formExpanded }"
        >
            <div class="grid p-fluid">
                <div class="form-row">
                    <div class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3">
                        <label for="friendly_id">{{ 'hub.quotations.filters.quotation-id' | translate }}</label>
                        <input
                            formControlName="friendly_id"
                            id="friendly_id"
                            pInputText
                            placeholder="{{ 'hub.quotations.filters.quotation-id' | translate }}"
                            type="text"
                        />
                    </div>

                    <div class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3">
                        <label for="product">{{ 'hub.quotations.filters.product' | translate }}</label>
                        <p-dropdown
                            [options]="loadTypes"
                            [showClear]="true"
                            appendTo="body"
                            formControlName="product"
                            overlayOptions="{
                                hideTransitionOptions: '0ms',
                                showTransitionOptions: '0ms'
                            }"
                            id="product"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="{{ 'hub.quotations.filters.product' | translate }}"
                        >
                            <ng-template let-option pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="option">
                                    <div>{{ option.label | translate }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-option pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ option.label | translate }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <div class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3">
                        <label for="status">{{ 'hub.quotations.filters.status' | translate }}</label>
                        <p-dropdown
                            [options]="statuses"
                            [showClear]="true"
                            appendTo="body"
                            formControlName="status"
                            overlayOptions="{
                                hideTransitionOptions: '0ms',
                                showTransitionOptions: '0ms'
                            }"
                            id="status"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="{{ 'hub.quotations.filters.status' | translate }}"
                        >
                            <ng-template let-option pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="option">
                                    <div>{{ option.label | translate }}</div>
                                </div>
                            </ng-template>

                            <ng-template let-option pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ option.label | translate }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <div class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3">
                        <label for="owner_id">{{ 'hub.quotations.filters.customer' | translate }}</label>
                        <p-autoComplete
                            (completeMethod)="searchOwners($event)"
                            [forceSelection]="true"
                            [showEmptyMessage]="true"
                            [suggestions]="owners$ | async"
                            appendTo="body"
                            dataKey="id"
                            optionLabel="name"
                            formControlName="owner_id"
                            id="owner_id"
                            placeholder="{{ 'common.type-to-search' | translate }}..."
                        >
                            <ng-template let-option pTemplate="item">
                                <span>
                                    {{ option.name }}
                                    {{ option.email }}
                                </span>
                            </ng-template>
                        </p-autoComplete>
                    </div>

                    <ng-container *ngIf="showMoreOptions">
                        <div
                            class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3"
                        >
                            <label for="origin_type">{{ 'hub.quotations.filters.origin' | translate }}</label>
                            <div
                                class="d-flex align-items-center justify-content-stretch"
                            >
                                <div class="pr-1" style="width: 33%">
                                    <p-dropdown
                                        [options]="locationTypes"
                                        [showClear]="true"
                                        appendTo="body"
                                        formControlName="origin_type"
                                        overlayOptions="{
                                            hideTransitionOptions: '0ms',
                                            showTransitionOptions: '0ms'
                                        }"
                                        id="origin_type"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="{{ 'common.type' | translate }}"
                                    >
                                        <ng-template let-option pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2" *ngIf="option">
                                                <div>{{ option.label | translate }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-option pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <div>{{ option.label | translate }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <input
                                    formControlName="origin_name"
                                    id="origin_name"
                                    pInputText
                                    placeholder="{{ 'hub.quotations.filters.origin' | translate }}"
                                    style="width: 67%"
                                    type="text"
                                />
                            </div>
                        </div>

                        <div
                            class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3"
                        >
                            <label for="destination_type">{{ 'hub.quotations.filters.destination' | translate }}</label>
                            <div
                                class="d-flex align-items-center justify-content-stretch"
                            >
                                <div class="pr-1" style="width: 33%">
                                    <p-dropdown
                                        [options]="locationTypes"
                                        [showClear]="true"
                                        appendTo="body"
                                        formControlName="destination_type"
                                        overlayOptions="{
                                            hideTransitionOptions: '0ms',
                                            showTransitionOptions: '0ms'
                                        }"
                                        id="destination_type"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="{{ 'common.type' | translate }}"
                                    >
                                        <ng-template let-option pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2" *ngIf="option">
                                                <div>{{ option.label | translate }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-option pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <div>{{ option.label | translate }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <input
                                    formControlName="destination_name"
                                    id="destination_name"
                                    pInputText
                                    placeholder="{{ 'hub.quotations.filters.destination' | translate }}"
                                    style="width: 67%"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div
                            class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3"
                        >
                            <label for="offer_count">{{ 'hub.quotations.filters.offers' | translate }}</label>
                            <div
                                class="d-flex align-items-center justify-content-stretch"
                            >
                                <div class="pr-1" style="width: 50%">
                                    <p-dropdown
                                        [options]="numberOperators"
                                        [showClear]="true"
                                        appendTo="body"
                                        formControlName="offer_count_operator"
                                        overlayOptions="{
                                            hideTransitionOptions: '0ms',
                                            showTransitionOptions: '0ms'
                                        }"
                                        id="offer_count_operator"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="{{ 'hub.quotations.filters.operator' | translate }}"
                                    >
                                        <ng-template let-option pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2" *ngIf="option">
                                                <div>{{ option.label | translate }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-option pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <div>{{ option.label | translate }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div style="width: 50%">
                                    <p-inputNumber
                                        formControlName="offer_count"
                                        id="offer_count"
                                        placeholder="{{ 'hub.quotations.filters.number-of-offers' | translate }}"
                                    ></p-inputNumber>
                                </div>
                            </div>
                        </div>
                        <div
                            class="form-group p-fluid col-12 col-md-6 col-xl-4 col-xxl-3"
                        >
                            <label for="account_manager_ids">{{ 'hub.quotations.filters.account-manager' | translate }}</label>
                            <p-multiSelect
                                [options]="hubUsers$ | async"
                                formControlName="account_manager_ids"
                                id="account_manager_ids"
                                placeholder="{{ 'hub.quotations.filters.account-manager' | translate }}"
                                optionLabel="name"
                                optionValue="id"
                                appendTo="body"
                            />
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col text-right p-0">
                    <button
                        (click)="showMoreOptions = !showMoreOptions"
                        class="btn btn-link btn-sm mr-2"
                        type="button"
                    >
                        {{ (showMoreOptions ? 'common.show-less-filters' : 'common.show-more-filters') | translate }}
                    </button>
                    <button [disabled]="disabled" class="btn btn-primary" type="submit">
                        {{ 'common.search' | translate }}
                    </button>
                    <button [disabled]="disabled" class="btn ml-2" type="reset">
                        {{ 'common.reset' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
