/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { QuotationsServiceBase } from '@qwyk/shared-stores/quotations';
import { Observable, of } from 'rxjs';
import { common, Portals } from '@qwyk/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, first, map, take } from 'rxjs/operators';
import { environment } from '@qwyk/hub/environment';
import { isDate } from 'moment/moment';

@Injectable({
    providedIn: 'root',
})
export class QuotationsService implements QuotationsServiceBase {
    constructor(private http: HttpClient) {}

    public getQuotations(
        query: any
    ): Observable<common.Pagination<Portals.QuotationEntity>> {
        return this.http
            .get<common.Pagination<Portals.QuotationEntity>>(
                `${
                    environment.backendServer
                }/api/hub/quotations/summary?${this.mapGetQuotationsQuery(
                    query
                )}`
            )
            .pipe(first());
    }

    public downloadQuotation(
        quotationId: string,
        offerId: string = null
    ): Observable<Blob> {
        let params = new HttpParams();
        if (offerId) {
            params = params.append('offer_id', offerId);
        }

        return this.http
            .post(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/download?${params}`,
                null,
                { responseType: 'blob' }
            )
            .pipe(
                first(),
                map(response => {
                    return new Blob([response], {
                        type: 'application/pdf',
                    });
                })
            );
    }

    public getQuotation(id: string): Observable<Portals.QuotationEntity> {
        return this.http
            .get<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations/${id}`
            )
            .pipe(
                first(),
                map(response => response.data)
            );
    }

    public calculateLineTotal(id: string, payload): Observable<any> {
        return this.http
            .post<any>(
                `${environment.backendServer}/api/hub/quotations/${id}/calculate_line`,
                payload
            )
            .pipe(first());
    }

    public createQuotationOffer(
        quotationId: string,
        payload: any
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .post<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations/${quotationId}/offers`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public findUser(term: string): Observable<any> {
        return this.http
            .get<{ data: any[] }>(
                `${
                    environment.backendServer
                }/api/hub/administration/users/search${
                    term ? '?term=' + term : ''
                }`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public closeQuotation(
        quotation: Portals.QuotationEntity
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .post<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations/${quotation.id}/close`,
                null
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public getNetworkedOrganizations() {
        return this.http
            .get<any[]>(
                `${environment.backendServer}/api/hub/administration/network-authorizations/mine`
            )
            .pipe(
                take(1),
                catchError(() => of(null))
            );
    }

    public createQuotation(
        payload: any,
        autorate: boolean
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .post<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations?autorate=${autorate}`,
                payload
            )
            .pipe(
                first(),
                map(response => response.data)
            );
    }

    public deleteQuotation(
        quotation: Portals.QuotationEntity
    ): Observable<any> {
        return this.http
            .delete<any>(
                `${environment.backendServer}/api/hub/quotations/${quotation.id}`
            )
            .pipe(take(1));
    }

    public deleteQuotationOffer(
        quotation: Portals.QuotationEntity,
        offer: Portals.QuotationOffer
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .delete<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations/${quotation.id}/offers/${offer.id}`
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public publishQuotation(
        quotation: Portals.QuotationEntity
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .post<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations/${quotation.id}/publish`,
                null
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public closeQuotationRateRequest(
        rateRequest: Portals.QuotationRateRequest,
        payload: { reason: string }
    ): Observable<Portals.QuotationEntity> {
        return this.http
            .post<{ data: Portals.QuotationEntity }>(
                `${environment.backendServer}/api/hub/quotations/${rateRequest.quotation_id}/rate-request/${rateRequest.id}/close`,
                payload
            )
            .pipe(
                take(1),
                map(response => response.data)
            );
    }

    public getNetworkQuotationInfo(
        quotation: Portals.QuotationEntity
    ): Observable<any> {
        return this.http.get<any>(
            `${environment.backendServer}/api/hub/quotations/${quotation.id}/network-quotation`
        );
    }

    createNetworkQuotationRequest(
        quotation: Portals.QuotationEntity,
        request: any
    ) {
        return this.http
            .post<void>(
                `${environment.backendServer}/api/hub/quotations/${quotation.id}/network-request`,
                request
            )
            .pipe(take(1));
    }

    private mapGetQuotationsQuery(query: any): HttpParams {
        Object.keys(query).forEach(k => {
            if (query[k] === null) {
                delete query[k];
            } else if (isDate(query[k])) {
                query[k] = query[k].toDateString();
            } else if (Array.isArray(query[k])) {
                query[`${k}[]`] = query[k];
                delete query[k];
            } else {
                query[k] = query[k].toString();
            }
        });

        return new HttpParams({ fromObject: query });
    }
}
