<ng-container [formGroup]="offerLineGroup">
    <tr [pEditableRow]="offerLineGroup">
        <td rowspan="2" style="vertical-align: middle">
            <!--            <span-->
            <!--                *ngIf="!disabled && !editing"-->
            <!--                class="pi pi-bars"-->
            <!--                pReorderableRowHandle-->
            <!--            >-->
            <!--            </span>-->
        </td>

        <td rowspan="2" style="vertical-align: middle">
            <ng-container *ngIf="editing">
                <p-dropdown
                    [options]="chargeSegments"
                    [overlayOptions]="{
                        hideTransitionOptions: '0ms',
                        showTransitionOptions: '0ms'
                    }"
                    [style]="{ width: '100%' }"
                    appendTo="body"
                    formControlName="segment"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="{{ 'hub.quotations.details.offer.columns.segment' | translate }}"
                >
                    <ng-template let-value pTemplate="selectedItem">
                        <span *ngIf="value">{{ value.label | translate }}</span>
                    </ng-template>
                    <ng-template let-value pTemplate="item">{{ value.label | translate }}</ng-template>
                </p-dropdown>
            </ng-container>

            <ng-container *ngIf="!editing">
                {{ offerLineGroup.get('segment')?.value | titlecase }}
            </ng-container>
        </td>

        <td style="vertical-align: middle">
            <ng-container *ngIf="editing">
                <p-dropdown
                    (onChange)="onChargeCodeChanged($event)"
                    [filter]="true"
                    filterBy="description"
                    [options]="chargeCodes"
                    [overlayOptions]="{
                        hideTransitionOptions: '0ms',
                        showTransitionOptions: '0ms'
                    }"
                    [style]="{ width: '100%' }"
                    appendTo="body"
                    formControlName="charge_code"
                    optionLabel="description"
                    placeholder="{{ 'hub.quotations.details.offer.columns.charge' | translate }}"
                >
                </p-dropdown>
            </ng-container>

            <ng-container *ngIf="!editing">
                {{ offerLineGroup.get('charge_description')?.value }}
            </ng-container>
        </td>

        <td style="vertical-align: middle">
            <ng-container *ngIf="editing">
                <p-dropdown
                    [filter]="true"
                    filterBy="code"
                    [options]="currencies"
                    [overlayOptions]="{
                        hideTransitionOptions: '0ms',
                        showTransitionOptions: '0ms'
                    }"
                    [style]="{ width: '100%' }"
                    (onChange)='onChangingRow()'
                    appendTo="body"
                    formControlName="base_currency"
                    optionLabel="code"
                    optionValue="code"
                    placeholder="{{ 'hub.quotations.create.fields.currency' | translate }}"
                >
                    <ng-template let-currency pTemplate="item">
                        <div>{{ currency.name }}</div>
                    </ng-template>
                </p-dropdown>
            </ng-container>

            <ng-container *ngIf="!editing">
                {{ offerLineGroup.get('base_currency')?.value }}
            </ng-container>
        </td>

        <td style="vertical-align: middle">
            <ng-container *ngIf="editing">
                <p-dropdown
                    [filter]="true"
                    filterBy="name"
                    [options]="rateCalculations"
                    [overlayOptions]="{
                        hideTransitionOptions: '0ms',
                        showTransitionOptions: '0ms'
                    }"
                    [style]="{ width: '100%' }"
                    (onChange)='onChangingRow()'
                    appendTo="body"
                    formControlName="base_calculation"
                    optionLabel="name"
                    placeholder="{{ 'hub.quotations.details.offer.columns.calculation' | translate }}"
                >
                    <ng-template let-calculation pTemplate="item">
                        <div>{{ calculation.name }}</div>
                        <div class="small text-muted">
                            {{ calculation.description }}
                        </div>
                    </ng-template>
                </p-dropdown>
            </ng-container>

            <ng-container *ngIf="!editing">
                {{ offerLineGroup.get('base_calculation')?.value?.name }}
            </ng-container>
        </td>

        <td>
            <ng-container *ngIf="editing">
                <p-inputNumber
                    (onKeyDown)='onChangingRow()'
                    (onBlur)="onBuyingRateInput()"
                    [maxFractionDigits]="2"
                    [minFractionDigits]="2"
                    formControlName="base_buying_rate"
                    inputStyleClass="w-100 text-right"
                    mode="decimal"
                ></p-inputNumber>
            </ng-container>

            <ng-container *ngIf="!editing">
                <div class="text-right">
                    {{
                        offerLineGroup.get('base_buying_rate')?.value
                            | currency: baseCurrency
                    }}
                </div>
            </ng-container>
        </td>

        <td>
            <ng-container *ngIf="editing">
                <p-inputNumber
                    (onKeyDown)='onChangingRow()'
                    (onBlur)="onMarginRateInput()"
                    [maxFractionDigits]="2"
                    [minFractionDigits]="2"
                    formControlName="base_markup_rate"
                    inputStyleClass="w-100 text-right"
                    mode="decimal"
                ></p-inputNumber>
            </ng-container>

            <ng-container *ngIf="!editing">
                <div class="text-right">
                    {{
                        offerLineGroup.get('base_markup_rate')?.value
                            | currency: baseCurrency
                    }}
                </div>
            </ng-container>
        </td>

        <td class="middle-v">
            <ng-container *ngIf="editing">
                <p-inputNumber
                    (onBlur)="onSellingRateInput()"
                    (onKeyDown)='onChangingRow()'
                    [maxFractionDigits]="2"
                    [minFractionDigits]="2"
                    formControlName="base_rate"
                    inputStyleClass="w-100 text-right"
                    mode="decimal"
                ></p-inputNumber>
            </ng-container>

            <ng-container *ngIf="!editing">
                <div class="text-right">
                    {{
                        offerLineGroup.get('base_rate')?.value
                            | currency: baseCurrency
                    }}
                </div>
            </ng-container>
        </td>

        <td class="font-weight-bold text-right middle-v">
            <ng-container>
                <div [ngClass]="{'editing-process': rowChanging, 'text-grey': rowChanging}">
                    {{
                        offerLineGroup.get('total_amount_quotation')?.value
                            | currency: offerCurrency
                    }}
                </div>
                <small class="text-info" *ngIf="rowChanging">{{ 'hub.quotations.details.offer.save-to-recalculate' | translate }}</small>
            </ng-container>
        </td>

        <td class="middle-v" rowspan="2">
            <button
                (click)="onEditRow()"
                *ngIf="!editing"
                [disabled]="disabled"
                class="p-button-rounded p-button-text"
                icon="pi pi-pencil"
                pButton
                type="button"
                ngbTooltip="{{ 'common.edit' | translate }}"
            ></button>
            <button
                (click)="onDeleteRow()"
                *ngIf="!editing"
                [disabled]="disabled"
                class="p-button-rounded p-button-text p-button-danger"
                icon="pi pi-trash"
                pButton
                type="button"
                ngbTooltip="{{ 'common.delete' | translate }}"
            ></button>
            <button
                [disabled]="offerLineGroup.invalid || disabled"
                (click)="onCompleteRowEdit()"
                *ngIf="editing"
                class="p-button-rounded p-button-text p-button-success p-mr-2"
                icon="pi pi-check"
                pButton
                pSaveEditableRow
                type="button"
                ngbTooltip="{{ 'common.save' | translate }}"
            ></button>
            <button
                (click)="onCancelRowEdit()"
                *ngIf="editing"
                [disabled]="disabled"
                class="p-button-rounded p-button-text p-button-warning"
                icon="pi pi-undo"
                pButton
                pCancelEditableRow
                type="button"
                ngbTooltip="{{ 'common.undo' | translate }}"
            ></button>
        </td>
    </tr>

    <tr [pEditableRow]="offerLineGroup">
        <td
            *ngIf="
                offerLineGroup.get('charge_code').value?.code ===
                    CHARGE_CODE_OTHER && editing
            "
        >
            <input
                class="w-100"
                formControlName="charge_description"
                pInputText
                placeholder="{{ 'hub.quotations.details.offer.columns.charge-name' | translate }}"
            />
        </td>

        <td
            [attr.colspan]="
                offerLineGroup.get('charge_code').value?.code !==
                    CHARGE_CODE_OTHER || !editing
                    ? 3
                    : 2
            "
        >
            <ng-container *ngIf="editing">
                <input
                    class="w-100"
                    formControlName="remarks"
                    pInputText
                    placeholder="{{ 'hub.quotations.details.offer.columns.charge-remarks' | translate }}"
                />
            </ng-container>

            <ng-container *ngIf="!editing">
                <div class="small text-muted">
                    {{ offerLineGroup.get('remarks')?.value }}
                </div>
            </ng-container>
        </td>

        <td>
            <ng-container *ngIf="editing">
                <p-inputNumber
                    (onKeyDown)='onChangingRow()'
                    [maxFractionDigits]="2"
                    [minFractionDigits]="2"
                    formControlName="base_buying_minimum"
                    inputStyleClass="w-100 text-right"
                    mode="decimal"
                ></p-inputNumber>
            </ng-container>

            <ng-container *ngIf="!editing">
                <div class="small text-muted text-right">
                    {{
                        offerLineGroup.get('base_buying_minimum')?.value
                            | currency: baseCurrency
                    }}
                </div>
            </ng-container>
        </td>

        <td class="small text-muted text-right">
            <ng-container
                *ngIf="offerLineGroup.get('base_rate')?.value > 0; else zero"
            >
                <span>
                    {{
                        offerLineGroup.get('base_markup_rate')?.value /
                            offerLineGroup.get('base_rate')?.value | percent
                    }}
                </span>
            </ng-container>
            <ng-template #zero> 0% </ng-template>
        </td>

        <td>
            <ng-container *ngIf="editing">
                <p-inputNumber
                    (onKeyDown)='onChangingRow()'
                    [maxFractionDigits]="2"
                    [minFractionDigits]="2"
                    formControlName="base_minimum"
                    inputStyleClass="w-100 text-right"
                    mode="decimal"
                ></p-inputNumber>
            </ng-container>

            <ng-container *ngIf="!editing">
                <div class="small text-muted text-right">
                    {{
                        offerLineGroup.get('base_minimum')?.value
                            | currency: baseCurrency
                    }}
                </div>
            </ng-container>
        </td>

        <td class="text-right small text-muted">
            <ng-container>
                <div [ngClass]="{'editing-process': rowChanging}">
                    {{
                    offerLineGroup.get('total_buying_amount_quotation')?.value
                        | currency: offerCurrency
                    }}
                </div>
                <small class="text-info" *ngIf="rowChanging">{{ 'hub.quotations.details.offer.save-to-recalculate' | translate }}</small>
            </ng-container>
        </td>
    </tr>
</ng-container>
