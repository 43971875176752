import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import {
    ValueMaskPipe,
    FormErrorComponent,
    FileUploadComponent,
    InfoTooltipComponent,
    AddressFormComponent,
    UserInfoTooltipComponent,
    LoadingIndicatorComponent,
} from '@qwyk/ui-standalone';

import {
    SiteViewComponent,
    SitesListComponent,
    SiteDetailComponent,
    SiteCreateComponent,
    SitesListWidgetComponent,
} from './components';
import * as fromSites from './+state/sites.reducer';
import { SitesFacade } from './+state/sites.facade';
import { SitesEffects } from './+state/sites.effects';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        TableModule,
        RouterModule,
        ValueMaskPipe,
        NgSelectModule,
        CheckboxModule,
        InputTextModule,
        OrderListModule,
        TranslateModule,
        FontAwesomeModule,
        ColorPickerModule,
        FormErrorComponent,
        ReactiveFormsModule,
        FileUploadComponent,
        AddressFormComponent,
        InfoTooltipComponent,
        ReactiveValidationModule,
        UserInfoTooltipComponent,
        LoadingIndicatorComponent,
        EffectsModule.forFeature([SitesEffects]),
        StoreModule.forFeature(fromSites.SITES_FEATURE_KEY, fromSites.reducer),
    ],
    declarations: [
        SitesListComponent,
        SiteViewComponent,
        SiteDetailComponent,
        SiteCreateComponent,
        SitesListWidgetComponent,
    ],
    providers: [SitesFacade],
    exports: [SitesListWidgetComponent],
})
export class HubAdministrationSitesWithoutRoutingModule {}
