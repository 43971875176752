import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Validators } from 'angular-reactive-validation';

import { Hub } from '@qwyk/models';

import { UsersService } from '../../services/users.service';

@Component({
    selector: 'qwyk-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent {
    private _user: Hub.HubUser;
    private _saving = false;
    private allowedRoles = ['Administrator', 'Owner'];

    @Input()
    public set user(user: Hub.HubUser) {
        this._user = user;
        if (user) {
            this.form = this.fb.group({
                name: [
                    user.name,
                    [
                        Validators.required(
                            this.translate.instant(
                                'hub.administration.portal-users.enter-a-name'
                            )
                        ),
                        Validators.maxLength(191, maxLength =>
                            this.translate.instant(
                                'common.validation.maxLength',
                                { maxLength },
                            )
                        ),
                        Validators.minLength(3, minLength =>
                            this.translate.instant(
                                'common.validation.minLength',
                                {
                                    field: this.translate
                                        .instant('common.name')
                                        .toLowerCase(),
                                    minLength,
                                }
                            )
                        ),
                    ],
                ],
                role_id: [
                    user.role_id,
                    [
                        Validators.required(
                            this.translate.instant(
                                'hub.settings.users.select-a-role'
                            )
                        ),
                    ],
                ],
            });
        } else {
            this.form.reset();
        }
    }

    public get user(): Hub.HubUser {
        return this._user;
    }

    @Input()
    public set saving(saving: boolean) {
        this._saving = saving;
        setTimeout(() => {
            if (saving) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        }, 0);
    }

    public get saving(): boolean {
        return this._saving;
    }

    @Input() public error = null;

    @Input() public currentUser: Hub.HubUser;

    @Output() public update: EventEmitter<{
        id: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: any;
    }> = new EventEmitter();

    @Output() public updateHubUserStatus: EventEmitter<{
        user: Hub.HubUser;
        action: 'activate' | 'deactivate';
    }> = new EventEmitter();

    public form: FormGroup;
    public rolesLoading = true;
    public roles$ = this.service
        .getRoles()
        .pipe(tap(() => (this.rolesLoading = false)));

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private service: UsersService,
        private translate: TranslateService
    ) {}

    public onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const payload = this.form.value;
        this.update.emit({ id: this.user.id, payload });
    }

    public canUpdateUserStatus(): boolean {
        return (
            this.currentUser.id !== this.user.id &&
            this.allowedRoles.includes(this.currentUser.role_name)
        );
    }

    public onDeactivate(): void {
        this.updateHubUserStatus.emit({
            user: this.user,
            action: 'deactivate',
        });
    }

    public onActivate(): void {
        this.updateHubUserStatus.emit({ user: this.user, action: 'activate' });
    }
}
