<header
    id="hubHeader"
    class="navbar navbar-light bg-primary navbar-expand-sm py-0 shadow-sm pl-0"
>
    <div class="container-fluid p-0">
        <div
            class="header-content w-100 d-flex flex-row justify-content-between"
        >
            <div class="title-container d-flex align-items-stretch">
                <button
                        id="hubNavbarBrandingButton"
                        class="d-flex align-items-center btn btn-primary btn-sm py-0 pl-0"
                        [routerLink]="['/']"
                >
                    <img
                        height="36"
                        src="https://cdn.qwyk.io/images/newlogos/SVG/magaya_avatar.svg" style="margin: 8px 8px;"
                        alt="Avatar"/>
                    <span
                        class="badge ml-2 rounded-pill"
                        [ngClass]="env.badge"
                        *ngIf="env.badge"
                        style="font-size: 0.9em !important;"
                        >{{ env.name }}</span
                    >
                </button>
            </div>

            <div
                class="navbar-collapse collapse align-items-stretch flex-grow-1 d-flex pl-3"
            >
                <ng-container *ngIf="userMeta$ | async; let userMeta">
                    <div
                        *ngIf="userMeta?.impersonating"
                        style="align-self: center"
                        class="bg-danger text-white px-2 py-1 rounded-pill"
                    >
                        <div>
                            <fa-icon
                                [icon]="['fas', 'exclamation-triangle']"
                                class="mx-2"
                            ></fa-icon>
                            {{ 'hub.menu.top-menu.impersonating-user' | translate }}
                            <a
                                href="javascript:void(0)"
                                class="text-white font-weight-bold mr-2"
                                (click)="resetImpersonation()"
                                >{{ 'common.reset' | translate | uppercase }}</a
                            >
                        </div>
                    </div>
                </ng-container>

                <div class="flex-grow-1"></div>

                <ul id="hubNavbar" class="nav navbar-nav d-flex flex-row navbar-sub-nav">
                    <li id="hubNavbarFeedbackItem" class="dropdown">
                        <a
                            class="btn btn-primary btn-sm btn-icon"
                            placement="bottom"
                            ngbTooltip="{{ 'hub.menu.top-menu.feedback' | translate }}"
                            target="_blank"
                            href="{{feedbackSite}}"
                        >
                            <fa-icon
                                [icon]="['fas', 'bullhorn']"
                                [fixedWidth]="true"
                            ></fa-icon>
                        </a>
                    </li>

                    <li class="dropdown" id="hubNavbarNotificationsItem">
                        <button
                            class="btn btn-primary btn-sm btn-icon"
                            placement="bottom"
                            ngbTooltip="{{ 'hub.menu.top-menu.notifications' | translate }}"
                            [routerLink]="['/', 'notifications']"
                        >
                            <fa-icon
                                [icon]="['fas', 'bell']"
                                [fixedWidth]="true"
                            ></fa-icon>
                        </button>
                    </li>

                    <li
                        id="hubNavbarProfileItem"
                        class="dropdown"
                        *ngIf="(user$ | async) as user"
                        (click)="menu.toggle($event)"
                    >
                        <button
                            type="button"
                            id="hubNavbarProfileItemButton"
                            class="btn btn-primary btn-sm avatar"
                        >
                            <img
                                class="rounded-circle border"
                                style="width: 32px; height: 32px"
                                [src]="
                                    'https://www.gravatar.com/avatar/' +
                                    (user.email | md5) +
                                    '?d=https://ui-avatars.com/api/' +
                                    (user.name | urlEncode) +
                                    '/32/ffffff/097aff/2//false/true/true'
                                "
                                alt="Avatar" />

                            <fa-icon
                                [icon]="['far', 'angle-down']"
                                class="caret-down ml-1"
                            ></fa-icon>
                        </button>
                    </li>

                    <p-menu #menu [model]="menuItems" [popup]="true">
                        <ng-template pTemplate="item" let-item>
                            <div *ngIf="item.divider" class="dropdown-divider"></div>
                            <div *ngIf="!item.routerLink && !item.command && !item.divider" class="px-4 py-1">
                                <span class="text-muted small" style="white-space: nowrap">{{ item.label | translate  }}</span>
                            </div>
                            <a
                                *ngIf="item.routerLink || item.command"
                                class="p-menuitem-link flex justify-content-between align-items-center px-4 py-2">
                                <div>
                                    <fa-icon
                                        [icon]="['far', item.icon]"
                                        [fixedWidth]="true"
                                        class="mr-1"
                                    ></fa-icon>
                                    <span>{{ item.label | translate }}</span>
                                </div>
                            </a>
                        </ng-template>
                    </p-menu>
                </ul>
            </div>
        </div>
    </div>
</header>

<div *ngIf="magayaConnectionStatus$ | async; let status" class="error-banner bg-danger text-center text-white" style="position: fixed;">
    <b>{{ status.message }}</b>
    <a href="mailto:support@magaya.com?subject=DFP Connection Error" class="text-white ml-2" target="_blank">{{ 'hub.menu.top-menu.contact-support' | translate }}</a>
</div>

<div class="page" [ngClass]="{'has-error-banner': null !== (magayaConnectionStatus$ | async)}">
    <app-side-bar [isMagayaNetwork]="isMagayaNetwork$ | async"></app-side-bar>
    <section
        class="page-container"
        [ngClass]="{ expanded: !menuSideBar.shown }"
    >
        <router-outlet></router-outlet>
    </section>
</div>
