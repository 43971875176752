import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MD5Pipe, UrlEncodePipe } from '@qwyk/ui-standalone';
import { PortalsTranslationModule } from '@qwyk/portals/translation';

import { UserRoutingModule } from './user-routing.module';
import { UserEffects } from './store/effects/user.effects';
import { userReducer } from './store/reducers/user.reducers';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileActivitiesComponent } from './components/profile-activities/profile-activities.component';

@NgModule({
    imports: [
        CommonModule,
        MD5Pipe,
        NgbModule,
        FormsModule,
        MomentModule,
        UrlEncodePipe,
        NgSelectModule,
        UserRoutingModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        PortalsTranslationModule,
        EffectsModule.forFeature([UserEffects]),
        StoreModule.forFeature('user', userReducer),
    ],
    declarations: [ProfileComponent, ProfileActivitiesComponent],
})
export class UserModule {
}
