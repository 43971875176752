import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { TableModule } from 'primeng/table';
import { EffectsModule } from '@ngrx/effects';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import {
    MD5Pipe,
    UrlEncodePipe,
    FormErrorComponent,
    AddressFormComponent,
    UserInfoTooltipComponent,
} from '@qwyk/ui-standalone';

import {
    UserCreateComponent,
    UserDetailComponent,
    UsersListComponent,
    UserViewComponent,
} from './components';

import * as fromUsers from './+state/users.reducer';
import { UsersEffects } from './+state/users.effects';
import { UsersFacade } from './+state/users.facade';

@NgModule({
    imports: [
        CommonModule,
        MD5Pipe,
        NgbModule,
        FormsModule,
        TableModule,
        RouterModule,
        UrlEncodePipe,
        NgSelectModule,
        DropdownModule,
        TranslateModule,
        InputTextModule,
        FontAwesomeModule,
        FormErrorComponent,
        ReactiveFormsModule,
        AddressFormComponent,
        ReactiveValidationModule,
        UserInfoTooltipComponent,
        EffectsModule.forFeature([UsersEffects]),
        StoreModule.forFeature(fromUsers.USERS_FEATURE_KEY, fromUsers.reducer),
    ],
    declarations: [
        UsersListComponent,
        UserViewComponent,
        UserDetailComponent,
        UserCreateComponent,
    ],
    providers: [UsersFacade],
})
export class HubSettingsUsersWithoutRoutingModule {}
