import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TableModule } from 'primeng/table';
import { MomentModule } from 'ngx-moment';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {
    NgbAlertModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveValidationModule } from 'angular-reactive-validation';

import { LineBreaksPipe, TransportModeIconPipe, UserInfoTooltipComponent } from '@qwyk/ui-standalone';

import {
    OfferLinesRowComponent,
    OfferLinesTableComponent,
    QuotationOfferCopyComponent,
    QuotationsOfferEditComponent,
    QuotationsOfferFormComponent,
    QuotationOfferCreateComponent,
    QuotationsOffersListComponent,
    QuotationsOfferListItemComponent,
    QuotationOfferListExpansionItemComponent,
} from './components';
import { QuotationOffersFacade } from './+state/quotation-offers.facade';
import * as fromQuotationOffers from './+state/quotation-offers.reducer';
import { QuotationOffersEffects } from './+state/quotation-offers.effects';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TableModule,
        MomentModule,
        RouterModule,
        DropdownModule,
        CalendarModule,
        NgbAlertModule,
        LineBreaksPipe,
        InputTextModule,
        TranslateModule,
        NgbTooltipModule,
        InputNumberModule,
        NgbDropdownModule,
        FontAwesomeModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        InputTextareaModule,
        NgbDatepickerModule,
        TransportModeIconPipe,
        ReactiveValidationModule,
        UserInfoTooltipComponent,
        StoreModule.forFeature(
            fromQuotationOffers.QUOTATIONOFFERS_FEATURE_KEY,
            fromQuotationOffers.reducer
        ),
        EffectsModule.forFeature([QuotationOffersEffects]),
    ],
    declarations: [
        OfferLinesRowComponent,
        OfferLinesTableComponent,
        QuotationOfferCopyComponent,
        QuotationsOfferFormComponent,
        QuotationsOfferEditComponent,
        QuotationsOffersListComponent,
        QuotationOfferCreateComponent,
        QuotationsOfferListItemComponent,
        QuotationOfferListExpansionItemComponent,
    ],
    providers: [QuotationOffersFacade],
    exports: [QuotationsOffersListComponent],
})
export class HubQuotationsOffersWithoutRoutingModule {}
