<div class="modal-body">
    <div class="lead">{{ 'hub.schedules.routing-guide.simulate-search' | translate }}</div>
    <p class="text-muted">{{ 'hub.schedules.routing-guide.simulate-a-search-to-verify-a-specific-setup' | translate }}</p>
    <input type="text" class="d-none" />
    <form class="qw-form" [formGroup]="simulateForm" (submit)="simulate()">
        <div class="form-row align-items-end">
            <div class="form-group col">
                <label>{{ 'common.quotations.search.origin' | translate }}</label>
                <p-autoComplete formControlName="origin" [forceSelection]="true" class="p-fluid"
                    [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)" field="display_name"
                    inputStyleClass="form-control form-control-sm mw-100" required
                    (onSelect)="onLocationSelect($event.value, 'origin')" (onClear)="onLocationClear($event, 'origin')">
                    <ng-template let-value pTemplate="item">
                        <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight">
                        </div>
                    </ng-template>
                </p-autoComplete>
                <div class="validation-error"
                    *ngIf="simulateForm.get('origin').invalid && simulateForm.get('origin').touched">
                    {{ 'hub.schedules.routing-guide.please-select-a-place-from-the-list' | translate }}
                </div>
            </div>

            <div class="form-group col">
                <label>{{ 'hub.quotations.create.fields.destination' | translate }}</label>
                <p-autoComplete formControlName="destination" [forceSelection]="true" class="p-fluid"
                    [suggestions]="searchLocationResults" (completeMethod)="searchLocation($event)" field="display_name"
                    inputStyleClass="form-control form-control-sm mw-100" required
                    (onSelect)="onLocationSelect($event.value, 'destination')"
                    (onClear)="onLocationClear($event, 'destination')">
                    <ng-template let-value pTemplate="item">
                        <div class="autocomplete-list-location p-2" [innerHtml]="value.highlight">
                        </div>
                    </ng-template>
                </p-autoComplete>
                <div class="validation-error"
                    *ngIf="simulateForm.get('destination').invalid && simulateForm.get('destination').touched">
                    {{ 'hub.schedules.routing-guide.please-select-a-place-from-the-list' | translate }}
                </div>
            </div>

            <div class="form-group col">
                <label>{{ 'hub.quotations.filters.product' | translate }}</label>
                <select class="form-control form-control-sm mw-100" formControlName="product">
                    <option [value]="'air'">{{ 'common.air' | translate }}</option>
                    <option [value]="'fcl'">FCL</option>
                    <option [value]="'lcl'">LCL</option>
                    <option [value]="'csl'">{{ 'hub.schedules.routing-guide.console' | translate }}</option>
                </select>
            </div>
            <div class="form-group col-auto">
                <button class="btn btn-primary btn-sm">{{ 'common.search' | translate }}</button>
            </div>
        </div>
    </form>

    <hr>

    <ng-container *ngIf="result$ | async; let result; else loading">
        <div class="text-center text-danger" *ngIf="result.error && result.status">
            <fa-icon [icon]="['far', 'exclamation-triangle']" class="mb-2"></fa-icon>
            <div>{{ result.error?.error || result.statusText }}</div>
        </div>
        <ul *ngIf="!result.error && result.length > 0">
            <li *ngFor="let leg of result">
                <div>{{ leg.origin }}<span class="ml-2 small" *ngIf="leg.vars?.days">({{ leg.vars.days }} {{ 'hub.schedules.routing-guide.connecting-days' | translate }})</span></div>
                <div class="leg-card py-5 d-flex flex-row align-items-center" *ngIf="leg.segment === 'leg'">
                    <div class="mr-2" style="margin-left: -16px; background-color: #fff; padding: 4px;">
                        <fa-icon *ngIf="!(leg.provider | isArray)"
                            [icon]="[ 'far', productIcons[leg.provider.product] ]" size="lg" [fixedWidth]="true">
                        </fa-icon>
                        <fa-icon *ngIf="leg.provider | isArray"
                            [icon]="[ 'far', productIcons[leg.provider[0].product] ]" size="lg" [fixedWidth]="true">
                        </fa-icon>
                    </div>
                    <div>
                        <div *ngIf="leg.provider | isArray">
                            <div *ngFor="let p of leg.provider">{{ p.name }}: {{ p.string }}</div>
                        </div>
                        <div *ngIf="!(leg.provider | isArray)">{{ leg.provider.name }}: {{ leg.provider.string }}</div>
                    </div>
                </div>
                <div class="leg-card py-5 d-flex flex-row align-items-center" *ngIf="leg.segment !== 'leg'">
                    <div style="margin-left: -16px; background-color: #fff; padding: 4px;">
                        <fa-icon [icon]="['far', 'truck']" class="mr-2" size="lg" [fixedWidth]="true"></fa-icon>
                    </div>
                    <span>{{ leg.segment | titlecase }}</span>
                </div>
            </li>
            <li>{{ result[ result.length - 1]?.destination }}</li>
        </ul>
    </ng-container>

</div>

<ng-template #loading>
    <fa-icon [icon]="['far', 'spinner']" pulse="true"></fa-icon>
</ng-template>
