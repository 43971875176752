import { Component, inject, OnInit } from '@angular/core';

import { SelectItem } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectSelectAllChangeEvent } from 'primeng/multiselect';

import { QuotationsFacade } from '@qwyk/shared-stores/quotations';

@Component({
    selector: 'qwyk-quotations-list',
    templateUrl: './quotations-list.component.html',
    styleUrls: ['./quotations-list.component.scss'],
})
export class QuotationsListComponent implements OnInit {

    private readonly translate = inject(TranslateService);
    private readonly quotations = inject(QuotationsFacade);

    public quotations$ = this.quotations.allQuotations$;
    public loadingQuotations$ = this.quotations.loading$;
    public query$ = this.quotations.query$;
    public pagination$ = this.quotations.pagination$;

    public selectAll = false;
    public pageSize = 25;
    public selectedColumns = [];
    public columns: SelectItem[] = [
        {
            label: 'hub.quotations.table.columns.friendly-id',
            value: {
                field: 'friendly_id',
                header: 'hub.quotations.table.columns.friendly-id',
                default: true,
                disabled: true,
                filter: true,
                sort: true,
                sort_key: 'friendly_id',
                hidden: false,
                default_sort: 1,
            },
            disabled: true,
        },
        {
            label: 'hub.quotations.table.columns.status',
            value: {
                field: 'status',
                header: 'hub.quotations.table.columns.status',
                default: true,
                disabled: true,
                filter: true,
                sort: true,
                sort_key: 'status',
                hidden: false,
                default_sort: 2,
            },
            disabled: true,
        },
        {
            label: 'hub.quotations.table.columns.owner-name',
            value: {
                field: 'owner.name',
                header: 'hub.quotations.table.columns.owner-name',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'owner.name',
                hidden: false,
                default_sort: 3,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.request-transport-mode',
            value: {
                field: 'request.transport_mode',
                header: 'hub.quotations.table.columns.request-transport-mode',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'request.transport_mode',
                hidden: false,
                default_sort: 4,
            },
            disabled: false,
        },
        {
            label: 'INCO Term',
            value: {
                field: 'request.inco_term',
                header: 'INCO Term',
                default: false,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'request.inco_term',
                hidden: false,
                default_sort: 5,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.request-origin-type',
            value: {
                field: 'request.origin.type',
                header: 'hub.quotations.table.columns.request-origin-type',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'request.origin.type',
                hidden: false,
                default_sort: 6,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.request-origin-name',
            value: {
                field: 'request.origin.name',
                header: 'hub.quotations.table.columns.request-origin-name',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'request.origin.name',
                hidden: false,
                default_sort: 7,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.request-destination-type',
            value: {
                field: 'request.destination.type',
                header: 'hub.quotations.table.columns.request-destination-type',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'request.destination.type',
                hidden: false,
                default_sort: 8,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.request-destination-name',
            value: {
                field: 'request.destination.name',
                header: 'hub.quotations.table.columns.request-destination-name',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'request.destination.name',
                hidden: false,
                default_sort: 9,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.offers-count',
            value: {
                field: 'offers_count',
                header: 'hub.quotations.table.columns.offers-count',
                default: true,
                disabled: false,
                filter: true,
                sort: true,
                sort_key: 'offers_count',
                hidden: false,
                default_sort: 10,
            },
            disabled: false,
        },
        {
            label: 'hub.quotations.table.columns.request-created-at',
            value: {
                field: 'request.created_at',
                header: 'hub.quotations.table.columns.request-created-at',
                default: true,
                disabled: true,
                filter: true,
                sort: true,
                sort_key: 'request.created_at',
                hidden: false,
                default_sort: 11,
            },
            disabled: true,
        },
    ];

    ngOnInit() {
        this.columns.forEach(col => {
            col.label = this.translate.instant(col.label);
            col.value.header = this.translate.instant(col.value.header);
        });
        this.selectedColumns = this.columns
            .filter(e => e.value.default)
            .map(e => e.value);
    }

    loadQuotationsLazy(event: TableLazyLoadEvent) {
        setTimeout(() => {
            this.search({
                pageSize: event.rows ?? this.pageSize,
                page:
                    event.first && event.rows
                        ? event.first / event.rows + 1
                        : 1,
            });
        }, 50);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSearch(searchEvent: any): void {
        this.search({ ...searchEvent, page: 1 });
    }

    public onSelectedColumnsChange() {
        this.restoreDefaultColumns();

        // Restore column sorting
        this.selectedColumns = this.selectedColumns.sort((a, b) =>
            a.default_sort > b.default_sort ? 1 : -1
        );
    }

    public onSelectAllChange(event : MultiSelectSelectAllChangeEvent) {
        this.selectAll = event.checked;
        if (event.checked) {
            this.selectedColumns = this.columns.map(col => col.value);
        } else {
            this.selectedColumns = this.columns
                .filter(col => col.value.default)
                .map(col => col.value);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private search(query: any) {
        this.quotations.loadQuotations(query);
    }

    private restoreDefaultColumns() {
        this.columns
            .filter(e => e.disabled)
            .forEach(col => {
                if (
                    !this.selectedColumns
                        .map(e => e.field)
                        .includes(col.value.field)
                ) {
                    this.selectedColumns.push(col.value);
                }
            });
    }
}
